import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loading } from "../../Components/Loading";
import { toast } from "react-toastify";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import useForm from "../../hooks/useForm";
import "./Auth.css";

import TextField from "@mui/material/TextField";
import Button from "../../Components/Button";
import Link from "../../Components/Link";

// Material UI Icons
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Container from "../../Components/Container";
import axios from "axios";

const validateInput = (formData, t) => {
  const { email, password } = formData;
  const emailRegex = /\S+@\S+\.\S+/;

  if (!emailRegex.test(email)) {
    toast.error(t("error.invalidEmail"));
    return false;
  }

  if (password.length < 8 || password.length > 20) {
    toast.error(t("error.invalidPassword"));
    return false;
  }

  return true;
};

export const Login = () => {
  const { t } = useTranslation("common");

  const initialState = { email: "", password: "" };
  const [formData, handleChange] = useForm(initialState);
  const [loginPasswordType, setLoginPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!validateInput(formData, t)) {
  //     return;
  //   }

  //   try {
  //     const response = await axios.post("/login", {
  //       email: formData.email,
  //       password: formData.password,
  //     });
  //     const { token } = response.data;

  //     // Store the token in local storage or cookies (for persistence across pages)
  //     localStorage.setItem("authToken", token);

  //     // Redirect user to a protected page (e.g., profile)
  //     navigate("/");
  //   } catch (err) {
  //     // Handle errors (e.g., invalid credentials, unverified account)
  //     if (err.response && err.response.status === 400) {
  //       setError(err.response.data); // Display error message
  //     } else {
  //       setError("Something went wrong. Please try again later.");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInput(formData, t)) {
      return;
    }

    setIsLoading(true);

    try {
      await signInWithEmailAndPassword(
        auth,
        formData.email.toLowerCase(),
        formData.password
      );
      // const user = userCredential.user;

      // if (!user.emailVerified) {
      //     await auth.signOut();
      //     toast.error(() => (
      //         <Container center column gap='1rem'>
      //             <p>Your email is not verified.</p>
      //             <Button pd='0.5rem' onClick={async () => await sendEmailVerification(user)}>
      //                 Send Verification
      //             </Button>
      //         </Container>
      //     ), { closeOnClick: true });
      // } else {
      toast.success(t("login.success"));
      navigate("/");
    } catch (error) {
      toast.error(t("login.failed"));
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  };

  const togglePasswordVisibility = () => {
    setLoginPasswordType(
      loginPasswordType === "password" ? "text" : "password"
    );
  };

  return (
    <section className="auth centered">
      <form onSubmit={handleSubmit} className="centered column">
        <Container center color="black" column w="80%" gap="1rem">
          <h3>{t("navbar.logIn")}</h3>

          <Container end w="100%">
            <TextField
              name="email"
              label={t("general.email")}
              variant="standard"
              value={formData.email.toLowerCase()}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
            <EmailIcon className="icon" />
          </Container>

          <Container end w="100%">
            <TextField
              type={loginPasswordType === "password" ? "password" : ""}
              name="password"
              label={t("general.password")}
              variant="standard"
              value={formData.password}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
            <Container
              onClick={togglePasswordVisibility}
              sx={{ cursor: "pointer" }}
            >
              {loginPasswordType === "password" ? (
                <VisibilityIcon className="icon" />
              ) : (
                <VisibilityOffIcon className="icon" />
              )}
            </Container>
          </Container>

          <Container w="100%">
            <Link inPage href="/reset_password">
              {t("login.forgotPassword")}
            </Link>
          </Container>

          <Button type="submit" w="100%" pd="0.75rem">
            {t("general.logInBtn")}
          </Button>

          <Container center color="black" w="100%" gap="0.2rem">
            <strong>{t("login.noAccount")}</strong>
            <Link href="/register" inPage>
              {t("general.register")}
            </Link>
          </Container>
        </Container>
      </form>

      {isLoading && <Loading />}
    </section>
  );
};
