import React, { useRef, useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Container from "./Container";
import useEmail from "../hooks/useEmail";

export const TicketComponent = () => {
  const qrCode =
    "https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://fiestaeventgroup.az";

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        width: "130mm",
        height: "45mm",
        boxShadow: "0 0 7px rgba(0, 0, 0, 0.5)",
        background: "white",
        backgroundImage: "url('/images/ada-school-ticket.webp')",
        backgroundSize: "100% 100%",
      }}
    >
      {/* <div
        style={{
          width: "7mm",
          padding: "8px 5px",
          background: "white",
          writingMode: "vertical-rl",
          textOrientation: "mixed",
        }}
      >
        <p style={{ fontSize: "5px", fontWeight: 600, color: "black" }}>
          CONTACT US:
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style={{ color: "#3C5E96" }}>RITZ CARLTON</span>
        </p>
        <p style={{ fontSize: "6px", fontWeight: 600, color: "#3C5E96" }}>
          +994 10 713 7007
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style={{ color: "black" }}>SHUSHA BALLROOM</span>
        </p>
      </div> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="170"
        viewBox="0 0 25 170"
      >
        {/* Background */}
        <rect width="50" height="180" fill="#ffffff" />

        {/* Vertical Text */}
        <text
          x="-70"
          y="85"
          fontSize="5"
          fontWeight="bold"
          fill="black"
          transform="rotate(90, 10, 90)"
        >
          CONTACT US:
        </text>
        <text
          x="-70"
          y="94"
          fontSize="6"
          fill="#3C5E96" // Set text color to blue
          fontWeight="bold" // Make text bold
          transform="rotate(90, 10, 90)"
        >
          +994 10 713 7007
        </text>

        <text
          x="25"
          y="85"
          fontSize="5"
          fontWeight="bold"
          fill="#3C5E96"
          transform="rotate(90, 10, 90)"
        >
          RITZ CARLTON
        </text>
        <text
          x="12"
          y="94"
          fontSize="6"
          fill="black" // Set text color to blue
          fontWeight="bold" // Make text bold
          transform="rotate(90, 10, 90)"
        >
          SHUSHA BALLROOM
        </text>
      </svg>

      <div
        style={{
          background: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderLeft: "1px solid black",
          gap: "8px",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img width="40" src="./images/ada-uni-logo.png" alt="Logo" />
        </div>
        <div
          style={{
            width: "18mm",
            height: "18mm",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img width="100%" src={qrCode} alt="QR Code" />
        </div>
        <p
          style={{
            fontSize: "10px",
            fontWeight: 600,
            color: "#3C5E96",
            textAlign: "center",
          }}
        >
          Kanan Gurbanli
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img width="20" src="./images/logo/no-text.png" alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export const compressImage = (canvas, quality = 1) => {
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
      },
      "image/jpeg",
      quality
    );
  });
};

export const sendPdfTicket = async (
  name,
  email,
  event,
  qrCodeID,
  ticket_type,
  setIsLoading
) => {
  const qrCode = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${qrCodeID}`;
  const ticketElement = document.createElement("div");

  if (event != "ADA University New Year Party") {
    ticketElement.innerHTML = `
      <div
        style="
          width: 446px;
          height: 631px;
          background-color: white;
          border-radius: 5px;
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          display: flex;
          flex-direction: column;
          border: 2px solid black;
          max-width: 600px;
          margin: 0 auto;
        "
      >
        <div
          style="
            position: relative;
            width: 100%;
            text-align: center;
            color: white;
          "
        >
          <img
            src="/images/ada-school-ticket.jpeg"
            alt="ADA School Ticket Background"
            style="width: 100%; height: auto; display: block"
          />

          <div
            style="
              position: absolute;
              top: 50%;
              left: 27%;
              transform: translate(-50%, -50%);
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            "
          >
            <div
              style="
                width: 80px;
                height: 80px;
                background-color: white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 15px;
                overflow: hidden;
              "
            >
              <img
                src="/images/ada-school-logo.png"
                alt="Event Logo"
                style="max-width: 90px; max-height: 90px"
              />
            </div>
            <h1 style="font-size: 18px; margin: 0px">ADA School</h1>
            <p style="font-size: 12px">NEW YEAR GALA</p>
          </div>
        </div>
        <div
          style="
            position: relative;
            flex-grow: 1;
            height: 100%;
            padding: 10px 25px 20px 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div
            style="
              position: absolute;
              top: 0;
              right: 0;
              width: 80px;
              height: 80px;
              background-color: white;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
            "
          >
            <img
              src="/images/logo/no-text.jpeg"
              alt="Event Logo"
              style="max-width: 60px; max-height: 60px"
            />
          </div>
          <div>
            <h2 style="font-size: 14px; margin: 10px 0; color: #333">
              <strong>Event Details</strong>
            </h2>
            <p style="font-size: 12px; margin: 5px 0; color: #666">
              <strong>Date:</strong> December 29, 2024
            </p>
            <p style="font-size: 12px; margin: 5px 0; color: #666">
              <strong>Time:</strong> 6:00 PM
            </p>
            <p style="font-size: 12px; margin: 5px 0; color: #666">
              <strong>Venue:</strong> RITZ CARLTON / SHUSHA BALLROOM
            </p>
          </div>

          <div
            style="
              width: 200px;
              height: 200px;
              background-color: #ecf0f1;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              margin: 15px auto;
            "
          >
            <img
              src="${qrCode}"
              alt="QR Code"
              style="max-width: 100%; max-height: 100%"
            />
          </div>

          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;
              border-top: 1px dashed #bdc3c7;
              padding-top: 10px;
              color: #000;
            "
          >
            <div style="font-size: 12px"><strong>Attendee:</strong> ${name}</div>
            <div
              class="ticket-number"
              style="font-weight: bold; font-size: 12px; color: #3498db"
            >
              ${qrCodeID}
            </div>
          </div>
        </div>
      </div>
    `;
  } else {
    ticketElement.innerHTML = `
  <div style="width:446px;height:665px;background-color:white;color:black;border-radius:3px;box-shadow:0 15px 30px rgba(0,0,0,.1);overflow:hidden;display:flex;flex-direction:column;border:1px solid gray;max-width:600px;margin:0">
    <div style="position:relative;width:100%;text-align:center;color:white">
      <img src="/images/tickets/adaUniHeader.jpeg" alt="ADA School Ticket Background" style="width:100%;height:auto;display:block"/>
    </div>
    <div style="position:relative;flex-grow:1;height:100%;padding:30px 15px 10px;display:flex;flex-direction:column">
      <div class="fiesta-logo" style="position:absolute;top:0;left:0;width:100%;background-color:white;display:flex;justify-content:center;align-items:center;overflow:hidden;opacity:.09">
        <img src="/images/tickets/adaUniBody.jpeg" alt="Event Logo" style="object-fit:cover;width:100%"/>
      </div>
      <div class="fiesta-logo" style="position:absolute;top:0;right:0;width:80px;height:80px;display:flex;justify-content:center;align-items:center;overflow:hidden">
        <img src="/images/ticket/fiesta-logo.png" alt="Event Logo" style="max-width:60px;max-height:60px"/>
      </div>

      <div class="place">
        <h2 style="margin:16px 0;font-size:14px;font-family:Georgia,serif;transform:scale(1,2)">MASQUERADE BALL</h2>
        <p style="margin:0;font-size:14px;font-family:'Trebuchet MS',sans-serif">GRAND PALACE (BÖYÜK SARAY)</p>
      </div>

      <div class="info" style="display:flex;margin:15px 0;align-items:start;gap:40px;font-family:'Trebuchet MS',sans-serif">
        <div class="date-person" style="display:flex;flex-direction:column">
          <div class="date" style="display:flex;align-items:center;gap:10px">
            <p style="font-size:12px">TARİX<br/>DATE</p>
            <p style="font-weight:600;font-size:24px;margin:0">22.12.2024</p>
          </div>
          <div class="date" style="display:flex;flex-direction:column;margin-top:10px;gap:5px">
            <p style="font-size:12px;margin:0">${name}</p>
            <p style="font-weight:600;font-size:12px;margin:0">${qrCodeID}</p>
          </div>
        </div>

        <div class="time-price" style="display:flex;flex-direction:column">
          <div class="time" style="display:flex;align-items:center;gap:10px">
            <p style="font-size:12px">SAAT<br/>TIME</p>
            <p style="font-weight:600;font-size:24px;margin:0">18:00</p>
          </div>
          <div class="price" style="display:flex;align-items:center;gap:10px">
            <p style="font-size:12px">QİYMƏT<br/>PRICE</p>
            <p style="font-weight:600;font-size:24px;margin:0">185.00 AZN</p>
          </div>
        </div>
      </div>

      <div class="general-info" style="width:100%;display:flex;flex-direction:column;gap:15px">
        <div class="top" style="width:100%;display:flex;gap:15px">
          <info class="en" style="width:33%">
            <h2 style="font-size:6px;font-style:italic;font-family:'Trebuchet MS',sans-serif">IMPORTANT NOTICE FOR ATTENDEES</h2>
            <div style="font-size:5px;margin:0;padding-left:1px">
              <li>Tickets are non-refundable and non-exchangeable.</li>
              <li>Each attendee must have an individual ticket and present it at the entrance.</li>
              <li>For security reasons, security personnel may conduct personal searches.</li>
              <li>The administration is not liable for counterfeit tickets purchased from private sellers or unauthorized vendors.</li>
              <li>Please retain your ticket until the event concludes.</li>
              <li>The QR code on the ticket is valid for single use only; do not share your ticket with others.</li>
              <li>It is recommended to arrive at the venue at least 30 minutes before the event begins for check-in.</li>
              <li>Attendees must carry their national ID card or a clear photo of it for verification.</li>
            </div>
          </info>

          <info class="ru" style="width:33%">
            <h2 style="font-size:6px;font-style:italic;font-family:'Trebuchet MS',sans-serif">ВАЖНАЯ ИНФОРМАЦИЯ ДЛЯ ПОСЕТИТЕЛЕЙ</h2>
            <div style="font-size:5px;margin:0;padding-left:1px">
              <li>Билеты не подлежат возврату и обмену.</li>
              <li>Каждый посетитель должен иметь отдельный билет и предъявить его на входе.</li>
              <li>Из соображений безопасности сотрудники службы охраны могут проводить личный досмотр.</li>
              <li>Администрация не несет ответственности за поддельные билеты, приобретенные у частных лиц или неофициальных продавцов.</li>
              <li>Сохраняйте билет до окончания мероприятия.</li>
              <li>QR-код на билете действителен только один раз; не передавайте билет другим лицам.</li>
              <li>Рекомендуется прибыть на мероприятие минимум за 30 минут до начала для прохождения регистрации.</li>
              <li>Посетители обязаны иметь при себе документ, удостоверяющий личность (или его четкую фотографию) для проверки.</li>
            </div>
          </info>

          <info class="ru" style="width:33%">
            <h2 style="font-size:6px;font-style:italic;font-family:'Trebuchet MS',sans-serif">ALICILAR ÜÇÜN VACİB MƏLUMAT</h2>
            <div style="font-size:5px;margin:0;padding-left:1px">
              <li>Biletlər geri qaytarılmır və dəyişdirilmir.</li>
              <li>Hər bir alıcı fərdi biletə sahib olmalı və onu girişdə təqdim etməlidir.</li>
              <li>Təhlükəsizlik məqsədilə mühafizə xidməti şəxsi yoxlama apara bilər.</li>
              <li>Təşkilatçı kənar şəxslərdən və ya qeyri-rəsmi satıcılardan alınmış saxta biletlərə görə məsuliyyət daşımır.</li>
              <li>Bilet tədbirin sonuna saxlanılmalıdır.</li>
              <li>Biletdəki QR kod yalnız bir dəfə istifadə üçün keçərlidir; biletinizi digər şəxslərə verməyin.</li>
              <li>Qeydiyyat üçün tədbirin başlamasına ən azı 30 dəqiqə qalmış gəlməyiniz tövsiyə olunur.</li>
              <li>Alıcılar şəxsiyyət vəsiqələrini (və ya onun aydın bir fotosunu) yoxlama üçün özləri ilə gətirməlidirlər.</li>
            </div>
          </info>
        </div>

        <div class="botoom" style="width:100%;display:flex;gap:15px">
          <info class="en" style="width:33%">
            <h2 style="font-size:6px;font-style:italic;font-family:'Trebuchet MS',sans-serif">PROHIBITED ITEMS AND ACTIONS</h2>
            <div style="font-size:5px;margin:0;padding-left:1px">
              <li>Professional photography and videography are strictly prohibited without official event accreditation.</li>
              <li>The following items are not permitted:</li>
              <p style="margin:1px 0 0 5px;font-size:5px;">1. Weapons, sharp objects, pyrotechnics, or flammable substances.</p>
              <p style="margin:0 0 0 5px;font-size:5px;">2. Large or cumbersome items that may cause inconvenience or damage.</p>
              <p style="margin:0 0 2px 5px;font-size:5px;">3. Drugs or any kind of food products.</p>
              <li>Any actions that compromise the safety or comfort of others are strictly forbidden.</li>
            </div>
          </info>

          <info class="ru" style="width:33%">
            <h2 style="font-size:6px;font-style:italic;font-family:'Trebuchet MS',sans-serif">ЗАПРЕЩЕННЫЕ ПРЕДМЕТЫ И ДЕЙСТВИЯ</h2>
            <div style="font-size:5px;margin:0;padding-left:1px">
              <li>Профессиональная фото и видеосъемка без официальной аккредитации организаторов мероприятия строго запрещена.</li>
              <li>Запрещено проносить:</li>
              <p style="margin:1px 0 0 5px;font-size:5px;">1. Оружие, острые предметы, пиротехнику или легковоспламеняющиеся вещества.</p>
              <p style="margin:0 0 0 5px;font-size:5px;">2. Громоздкие или загрязняющие предметы, создающие неудобства.</p>
              <p style="margin:0 0 2px 5px;font-size:5px;">3. Наркотические вещества или любые продукты питания.</p>
              <li>Строго запрещены любые действия, угрожающие безопасности и комфорту других посетителей.</li>
            </div>
          </info>

          <info class="ru" style="width:33%">
            <h2 style="font-size:6px;font-style:italic;font-family:'Trebuchet MS',sans-serif">QADAĞAN OLUNMUŞ ƏŞYALAR VƏ HƏRƏKƏTLƏR</h2>
            <div style="font-size:5px;margin:0;padding-left:1px">
              <li>Rəsmi tədbir akkreditasiyası olmadan peşəkar foto və video çəkiliş qəti qadağandır.</li>
              <li>Aşağıdakı əşyaların gətirilməsi qadağandır:</li>
              <p style="margin:1px 0 0 5px;font-size:5px;">1. Silahlar, kəsici alətlər, pirotexniki vasitələr və ya alışqan maddələr.</p>
              <p style="margin:0 0 0 5px;font-size:5px;">2. Narahatlıq və ya zərər verə biləcək iri və ya çirkli əşyalar.</p>
              <p style="margin:0 0 2px 5px;font-size:5px;">3. Narkotik vasitələr və hər hansı qida məhsulları.</p>
              <li>Digər alıcıların təhlükəsizliyini və rahatlığını təhdid edən hərəkətlər qəti qadağandır.</li>
            </div>
          </info>
        </div>
      </div>
      <div class="end" style="display:flex;justify-content:space-between;align-items:end">
        <div class="qr-code" style="width:70px;height:70px;display:flex;justify-content:center;align-items:center">
          <img width="100%" src="${qrCode}" alt="QR Code"/>
        </div>
        <div class="icons" style="position:absolute;bottom:10px;left:50%;transform:translateX(-50%);display:flex;gap:2px">
          <div style="width:15px;height:15px"><img width="100%" src="/images/ticket/no-pets.png" alt=""/></div>
          <div style="width:15px;height:15px"><img width="100%" src="/images/ticket/no-arms-sign.png" alt=""/></div>
          <div style="width:15px;height:15px"><img width="100%" src="/images/ticket/no-photo.png" alt=""/></div>
          <div style="width:15px;height:15px"><img width="100%" src="/images/ticket/paint.png" alt=""/></div>
          <div style="width:15px;height:15px"><img width="100%" src="/images/ticket/no-food.png" alt=""/></div>
          <div style="width:15px;height:15px"><img width="100%" src="/images/ticket/no-fireworks.png" alt=""/></div>
          <div style="width:15px;height:15px"><img width="100%" src="/images/ticket/no-drinking.png" alt=""/></div>
        </div>

        <div class="contact" style="display:flex;flex-direction:column;align-items:end">
          <p style="margin:0;font-size:10px;font-weight:500;font-family:'Trebuchet MS',sans-serif">Dəstək / Support</p>
          <p style="margin:0;font-size:12px;font-weight:500;font-family:'Trebuchet MS',sans-serif">+994 10 713 70 07</p>
        </div>
      </div>
    </div>
  </div>
  `;
  }
  document.body.appendChild(ticketElement);

  // Generate the PDF directly from the ticketElement
  const generatePDF = async () => {
    if (event == "ADA University New Year Party") {
      try {
        // Use html2canvas to generate a canvas from the ticketElement
        const canvas = await html2canvas(ticketElement, {
          scale: 2, // Increase scale for better quality
          useCORS: true, // Handle cross-origin images
          scrollY: -window.scrollY, // Adjust for scrolling
        });

        // Get the canvas dimensions
        const canvasWidth = 446;
        const canvasHeight = 665;

        // Get the image data from the canvas
        const imageData = canvas.toDataURL("image/png");

        // Create a jsPDF instance
        const pdf = new jsPDF("portrait", "px", [canvasWidth, canvasHeight]);

        // Add the image to the PDF with original dimensions
        pdf.addImage(
          imageData,
          "PNG",
          0,
          0,
          canvasWidth * 3 + 100,
          canvasHeight
        );

        // Convert the PDF to a Blob
        const pdfBlob = pdf.output("blob");

        // Convert the Blob to Base64
        const fileReader = new FileReader();
        fileReader.readAsDataURL(pdfBlob);

        fileReader.onloadend = () => {
          const compressedBase64 = fileReader.result.split(",")[1]; // Extract Base64 string

          // Use the email function to send the PDF
          useEmail(
            "template_y1l8h4a", // Replace with your EmailJS template ID
            {
              event: event,
              user_email: email,
              ticket: compressedBase64, // Attach the Base64-encoded ticket
            },
            setIsLoading // Pass the loading state handler
          );
        };

        // Save the PDF locally (optional)
      } catch (error) {
        console.error("Error generating or sending PDF:", error);
      }
    } else {
      const pdf = new jsPDF({
        unit: "px",
        format: "a4", // Use A4 format, you can adjust the size as needed
      });

      // Use jsPDF's HTML method to render the ticket element directly into the PDF
      pdf.html(ticketElement, {
        callback: (pdf) => {
          const pdfBlob = pdf.output("blob");

          // Send via email
          const fileReader = new FileReader();
          fileReader.readAsDataURL(pdfBlob);
          fileReader.onloadend = () => {
            const compressedBase64 = fileReader.result.split(",")[1];
            useEmail(
              "template_y1l8h4a",
              {
                event: event,
                user_email: email,
                ticket: compressedBase64,
              },
              setIsLoading
            );
          };
        },
        x: 0,
        y: 0,
        width: 595, // Width for A4 format in px
        height: 842, // Height for A4 format in px
      });
    }
  };

  generatePDF().then(() => {
    document.body.removeChild(ticketElement); // Clean up after generating PDF
  });
};
