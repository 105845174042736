import Container from "../../Components/Container";
import Button from "../../Components/Button";
import { FaUsers, FaEnvelopeOpenText } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { PuffLoader } from "react-spinners";
import Link from "../../Components/Link";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FiestaTeam } from "../../forms/FiestaTeam";
import { ContactRequest } from "../../forms/ContactRequest";
import { contactInfo, mediaLinks } from "./constants";

export const ContactUs = () => {
  const { t } = useTranslation("common");
  const [mapLoaded, setMapLoaded] = useState(false);

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const [formType, setFormType] = useState("team");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container center column w="100%">
      <Container center w="90%" m="2rem 0" gap="2rem" className="row-column">
        <Container center w="100%" style={{ flex: 1 }}>
          <Container column gap="1rem" w="90%">
            <Container column>
              <h2>{t("contacts.header")}</h2>
              <h4 style={{ color: "rgb(47, 182, 253)" }}>
                {t("contacts.subHeader")}
              </h4>
            </Container>

            <Container gap="0.5rem" column>
              {contactInfo.map((info, index) => (
                <Container gap="0.5rem" center key={index}>
                  {info.icon}
                  <p>{t(`${info.text}`)}</p>
                </Container>
              ))}
            </Container>

            <Container gap="0.7rem">
              {mediaLinks.map((link, index) => (
                <Link key={index} href={link.href} children={link.icon} variant="media" />
              ))}
            </Container>
          </Container>
        </Container>

        <Container center w="100%" style={{ flex: 1 }}>
          {!mapLoaded && (
            <Container
              w="85%"
              column
              center
              bg="white"
              gap="1rem"
              rounded
              color="black"
              sx={{
                aspectRatio: 1,
                textAlign: "center",
              }}
            >
              <PuffLoader size={60} color="black" />
              <p>{t("contacts.map")}</p>
            </Container>
          )}

          <iframe
            title="Google_map"
            loading="lazy"
            onLoad={handleMapLoad}
            allow="fullscreen"
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1769.861376279711!2d49.82822489384829!3d40.38484362845132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d9bc036ce81%3A0x27aa9ede537ed392!2sEM%26C%20EDUCATIONAL%20CENTER!5e0!3m2!1sru!2saz!4v1727209210183!5m2!1sru!2saz"
            style={{
              width: mapLoaded ? "85%" : "0",
              aspectRatio: 1,
              borderRadius: "0.5rem",
            }}
          />
        </Container>
      </Container>

      <Container center w="70%" pd="5rem 0" gap="6rem" className="row-column">
        <Button
          variant="modern"
          shadow
          pd="2rem 0"
          w="100%"
          onClick={() => {
            handleOpen();
            setFormType("team");
          }}
          children={
            <Container center w="100%" column color="rgb(0, 14, 44)">
              <FaUsers style={{ fontSize: "7rem" }} />
              <h4>
                <strong>{t('contacts.team')}</strong>
              </h4>
            </Container>
          }
        />
        <Button
          variant="modern"
          shadow
          pd="2rem 0"
          w="100%"
          onClick={() => {
            handleOpen();
            setFormType("contact");
          }}
          children={
            <Container center w="100%" column color="rgb(0, 14, 44)">
              <FaEnvelopeOpenText
                style={{ fontSize: "5.5rem", marginBottom: "1.5rem" }}
              />
              <h4>
                <strong>{t('contacts.message')}</strong>
              </h4>
            </Container>
          }
        />

        <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              minHeight: "100%",
              overflow: "scroll",
              position: "relative",
            }}
            className="centered"
          >
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <CloseIcon />
            </IconButton>

            {formType === "team" && <FiestaTeam />}

            {formType === "contact" && <ContactRequest />}
          </div>
        </Modal>
      </Container>
    </Container>
  );
};

