import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GB, RU, AZ } from "country-flag-icons/react/3x2";
import Popover from "@mui/material/Popover";
import Container from "../../Container";
import Button from "../../Button";

const languages = [
  { code: "en", icon: <GB className="language-flag" /> },
  { code: "ru", icon: <RU className="language-flag" /> },
  { code: 'az', icon: <AZ className='language-flag'/> }
];

export const LanguageSelector = ({ toggleMenu = () => {} }) => {
  const [t, i18n] = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    handleClose();
    toggleMenu();
  };

  const open = Boolean(anchorEl);
  const id = open ? "language-popover" : undefined;

  const getCurrentFlag = () => {
    const currentLang = languages.find((lang) => lang.code === i18n.language);
    return currentLang ? currentLang.icon : languages[0].icon;
  };

  return (
    <div>
      <Button variant="secondary" pd="0.6rem 0.8rem" onClick={handleClick}>
        {getCurrentFlag()}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Container column>
          {languages
            .filter((lang) => lang.code !== i18n.language)
            .map((lang) => (
              <Button
                pd="0.6rem 0.8rem"
                variant="ordinary"
                sx={{ borderBottom: "0.1rem solid rgb(226, 226, 226)" }}
                key={lang.code}
                onClick={() => changeLanguage(lang.code)}
              >
                {lang.icon}
              </Button>
            ))}
        </Container>
      </Popover>
    </div>
  );
};
