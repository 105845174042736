import "./Team.css";
import { useState, useMemo } from "react";
import { Loading } from "../../Components/Loading";
import useForm from "../../hooks/useForm";
import useEmail from "../../hooks/useEmail";
import useCurrentUser from "../../hooks/useCurrentUser";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import Button from "../../Components/Button";
import { Reservations } from "./Reservations";
import Select from "../../Components/Select";
import Container from "../../Components/Container";
import CustomTable from "../../Components/CustomTable";

export const Team = () => {
  const initialState = {
    from_email: "",
    to_email: "",
    from_name: "",
    subject: "",
    html_message: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [userData] = useCurrentUser();
  const [formData, handleChange, updateFormData] = useForm(initialState);

  const team = useFirestoreCollection("team", setIsLoading);
  const reservations = useFirestoreCollection("reservations", setIsLoading);

  const handleSubmit = (e) => {
    e.preventDefault();
    useEmail(
      "template_qs86jrb",
      {
        user_email: formData.from_email,
        subject: "Happy Birthday!",
        message: `Dear ${user.name} ${user.surname},
    
    🎉 Happiest Birthday Wishes from Your Fiesta Family! 🎉
    
    On this special day, we want to take a moment to send you our warmest and most heartfelt wishes. May this year be filled with joy, success, and incredible achievements that bring you closer to your dreams. ✨
    
    As part of the Fiesta Team, we are beyond excited to celebrate this wonderful milestone with you. Here's to many more shared moments of happiness, laughter, and success in the future! 🎂🥳
    
    Enjoy every moment of your special day to the fullest—today is all about YOU! 💫
    
    Warmest regards,
    Your Fiesta Family`,
      },
      setIsLoading
    );
    updateFormData(initialState);
  };

  const filteredUsers = useMemo(() => {
    return team.filter((member) =>
      query ? member.position === query : member
    );
  }, [team, query]);

  return (
    <section className="team">
      <Container w="100%" center m="1rem 0 0 0">
        <h1>Team Members</h1>
      </Container>

      <Container w="90%" m="2rem 0" center>
        <Select
          onChange={(e) => setQuery(e.target.value)}
          variant="ordinary"
          w="7rem"
          placeholder="All"
          options={[
            { value: "media", label: "Media" },
            { value: "website", label: "Website" },
            { value: "venue", label: "Venue" },
          ]}
        />
      </Container>

      <Container w="90%" gap="1.5rem" m="2rem" center sx={{ flexWrap: "wrap" }}>
        {filteredUsers.map((member, index) => {
          const imgSrc = `https://drive.google.com/thumbnail?id=${member.photo}&sz=w1000`;
          return (
            <div
              key={index}
              className="team-member"
              style={{
                backgroundImage: `url(/images/team/bg/${member.position}.jpg)`,
              }}
            >
              <img src={imgSrc} alt={member.name} />
              <div className="info">
                <p>{member.name}</p>
                <p>{member.email}</p>
                <p>{member.phone}</p>
                <p>{member.position}</p>
              </div>
            </div>
          );
        })}
      </Container>

      <CustomTable
        columns={[
          { id: "id", label: "ID" },
          { id: "timestamp", label: "Date" },
          { id: "name", label: "Name" },
          { id: "surname", label: "Surname" },
          { id: "phone", label: "Phone" },
          { id: "table_type", label: "Type" },
          { id: "tableMates", label: "Table" },
        ]}
        rows={reservations}
      />

      {/* <Container w="80%" column center gap="0.5rem" m="2rem 0">
        <h1 className="team-header">Send Email</h1>
        <form className="team-form" onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="from_email"
            value={userData ? userData.email : ""}
            onChange={handleChange}
          />
          <div>
            <label>To: </label>
            <select name="to_email" onChange={handleChange}>
              <option value={team.map((member) => member.email).join(",")}>
                All Members
              </option>
              {team.map((member, index) => (
                <option key={index} value={member.email}>
                  {member.name}
                </option>
              ))}
            </select>
          </div>
          <input
            type="text"
            name="from_name"
            placeholder="Sender Name"
            value={formData.from_name}
            onChange={handleChange}
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            className="team-email-subject"
            value={formData.subject}
            onChange={handleChange}
          />
          <textarea
            name="html_message"
            placeholder="Message"
            value={formData.html_message}
            onChange={handleChange}
          />
          <p
            style={{
              color: "black",
              fontStyle: "italic",
              fontSize: "0.8rem",
              fontWeight: 600,
            }}
          >
            Note: No greetings are needed
          </p>
          <Button type="submit" variant="ordinary" isLoading={isLoading}>
            Send
          </Button>
        </form>
      </Container> */}

      {isLoading && <Loading />}
    </section>
  );
};

export default Team;
