import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import { Html5QrcodeScanner } from "html5-qrcode";
import { toast } from "react-toastify";
import { CheckBox, Cancel, RepeatOnOutlined } from "@mui/icons-material";
import { db } from "../../fb";
import { updateDocument } from "../../fb/FirebaseFunctions";
import { Loading } from "../../Components/Loading";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./Scanner.css";

const COLLECTION_NAME = "Black_Bloody_Jack";

const UserStatus = {
    NONE: 'none',
    LOADING: 'loading',
    FOUND: 'found',
    NOT_FOUND: 'notFound'
};

const TicketColors = {
    VIP: 'red',
    PREMIUM: 'green',
    DEFAULT: 'blue'
};

export const Scanner3 = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [userState, setUserState] = useState(UserStatus.NONE);

    const fetchUserData = useCallback(async (result) => {

        if (userState !== UserStatus.FOUND && userState !== UserStatus.NOT_FOUND) {
            setUserState(UserStatus.LOADING);
        }
    
        const q = query(collection(db, COLLECTION_NAME), where("qr_code", "==", result));
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                setUserData({ ...doc.data(), id: doc.id });
                setUserState(UserStatus.FOUND);
            });
        } else {
            setUserState(UserStatus.NOT_FOUND);
        }
    }, [userState]);

    useEffect(() => {
        const qrScanner = new Html5QrcodeScanner("reader", {
            fps: 10,
            qrbox: { width: 250, height: 250 },
            ghosting: true,
        });

        qrScanner.render(fetchUserData, () => {});

    }, []);

    const markUser = async (user) => {
        try {
            await updateDocument(COLLECTION_NAME, user.id, { marked: true }, setIsLoading);
            toast.success(`${user.name} ${user.surname} is Marked!`);
        } catch (error) {
            toast.error("Error Marking the User.");
            console.error("Error marking user:", error.message);
        }
    };

    const resetUserState = () => setUserState(UserStatus.NONE);

    const renderUserInfo = () => (
        <Container color='black' center gap="0.2rem" column w="100%">
            <h5>{userData.name} {userData.surname}</h5>
            <h5 style={{ color: TicketColors[userData.ticket] || TicketColors.DEFAULT }}>
                <strong>{userData.ticket}</strong>
            </h5>
        </Container>
    );

    const renderUserStatus = () => {
        switch (userState) {
            case UserStatus.LOADING:
                return <p>Scanning...</p>;
            case UserStatus.NOT_FOUND:
                return (
                <Container column center gap="1rem" w='100%'>
                    <Cancel color="error" sx={{ fontSize: "5rem" }} />
                    <h5 style={{ color: "red" }}>No such QR Code!</h5>
                    
                    <Button variant="error" onClick={resetUserState}>
                        <h5>Cancel</h5>
                    </Button>
                </Container>
                );
            case UserStatus.FOUND:
                return userData.marked ? (
                <Container column center gap="1rem" w='100%'>
                    <RepeatOnOutlined color="error" sx={{ fontSize: "5rem" }} />
                    <h5 style={{ color: "red" }}>QR Code is Used!</h5>
                    {renderUserInfo()}

                    <Button variant="error" onClick={resetUserState}>
                        <h5>Cancel</h5>
                    </Button>
                </Container>
                ) : (
                <Container gap="1rem" column center w="100%">
                    <CheckBox color="success" sx={{ fontSize: "5rem" }} />
                        {renderUserInfo()}
                    <Container gap="1rem" w="100%">

                    <Button variant="error" onClick={resetUserState}>
                        <h5>Cancel</h5>
                    </Button>

                    <Button
                        variant="success"
                        onClick={() => {
                        markUser(userData);
                        resetUserState();
                        }}
                    >
                        <h5>Accept</h5>
                    </Button>
                    </Container>
                </Container>
                );
            default:
                return null;
        }
    };

    return (
        <section className="users_container">
            <Button
                variant="ordinary"
                m="1rem"
                pd="0.5rem"
                onClick={() => navigate('/')}
                sx={{ position: "absolute", top: "0", left: "0" }}
            >
                <ArrowBackIosNewIcon fontSize="small" />
            </Button>

            <Container m='1rem 0 0 0'>
                <h3>Venue</h3>
            </Container>

            <Container center column gap="2rem">
                <div id="reader"></div>
                {userState !== UserStatus.NONE && (
                <Container rounded shadow center bg="white" color="black" pd="1rem" m="0 0 2rem 0">
                    {renderUserStatus()}
                </Container>
                )}
            </Container>

            {isLoading && <Loading />}
        </section>
    );
};