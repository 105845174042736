import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from './LanguageSelector';
import useCurrentUser from '../../../hooks/useCurrentUser';

import Popover from '@mui/material/Popover';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

import Container from '../../Container';
import Button from '../../Button';
import { IconButton } from '@mui/material';

export const NavEnd = ({ className, toggleMenu = () => {}, handleSignOut }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData] = useCurrentUser();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = useCallback(
    (path) => {
      toggleMenu();
      navigate(path);
    },
    [toggleMenu, navigate]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container center gap='0.5rem' className={className}>
      <LanguageSelector toggleMenu={toggleMenu} />

      {!userData ? (
        <Button pd='0.4rem 0.7rem' onClick={() => handleClick('/login')}>
          <LoginIcon />
          {t('navbar.logIn')}
        </Button>
      ) : (
        <Container center column>
          <IconButton
            onClick={handleMenu}
            sx={{color: 'white'}}
          >
            <AccountCircle fontSize='large' />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {userData && (
              <Container center column pd='1rem 1.5rem' gap='1rem'>
                <Container w='100%' center gap='0.5rem'>
                  <Container
                    center
                    sx={{
                      background: 'rgb(98, 98, 98)',
                      width: '3rem',
                      aspectRatio: 1,
                      borderRadius: '50%',
                      color: 'white',
                    }}
                  >
                    <h5>
                      {userData.name.charAt(0).toUpperCase()}
                      {userData.surname.charAt(0).toUpperCase()}
                    </h5>
                  </Container>

                  <Container color='black' column>
                    <h5>
                      <strong>
                        {userData.name} {userData.surname}
                      </strong>
                    </h5>
                    <p style={{ color: 'rgba(30, 30, 30, 0.6)' }}>
                      {userData.email}
                    </p>
                  </Container>
                </Container>

                <Button
                  variant='error'
                  pd='0.4rem 0.7rem'
                  onClick={() => {
                    handleSignOut();
                    setAnchorEl(null);
                  }}
                  color='error'
                  fullWidth
                >
                  <LogoutIcon />
                  {t('navbar.signOut')}
                </Button>
              </Container>
            )}
          </Popover>
        </Container>
      )}
    </Container>
  );
};
