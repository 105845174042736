import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/Container";

export const Stats = () => {
  const { t } = useTranslation("common");
  return (
    <Container
      id='stats'
      w="100%"
      center
      bg="rgb(0, 44, 74)"
      pd="2rem 0"
      sx={{ boxShadow: "0 0 9.4rem 9.4rem rgb(0, 44, 74)" }}
    >
        <Container
          w="85%"
          className="row-column"
          gap="0.5rem"
          sx={{ justifyContent: "space-between" }}
        >
          <StatData num="2" text={t("home.stats.stats1")} />
          <StatData num="40+" text={t("home.stats.stats2")} />
          <StatData num="4000+" text={t("home.stats.stats3")} />
          <StatData num="15+" text={t("home.stats.stats4")} />
        </Container>

    </Container>
  );
};

const StatData = (props) => {
  return (
    <Fade direction="down" duration={2000} triggerOnce>
      <Container column>
        <h1>{props.num}</h1>
        <h5 style={{ color: "rgb(65, 189, 255)" }}>{props.text}</h5>
      </Container>
    </Fade>
  );
};
