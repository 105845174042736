import { useState, useMemo, useEffect } from "react";
import { Loading } from "../../Components/Loading";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { doc, collection, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../fb";
import "./Scanner.css";

export const Reception = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [participants, setParticipants] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(
    "ADA_School_Party_Participants"
  );

  const collections = [
    { name: "Another Collection", value: "another_collection" },
  ];

  const handleCollectionChange = (event) => {
    const selected = collections.find((c) => c.value === event.target.value);
    setSelectedCollection(selected.value);
  };

  async function fetchData(collectionName) {
    setIsLoading(true);

    const participantsSnapshot = await getDocs(collection(db, collectionName));
    setParticipants(
      participantsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );

    setIsLoading(false);
  }

  useEffect(() => {
    fetchData(selectedCollection);
  }, [selectedCollection]);

  const handleChangeStatus = async (email, field) => {
    setIsLoading(true);
    const updateField = {};
    updateField[field] = true;

    try {
      await updateDoc(doc(db, selectedCollection, email), updateField);
      toast.success("Done!");
      fetchData(selectedCollection);
    } catch (error) {
      toast.error("Error.");
      console.error("Error:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredParticipants = useMemo(() => {
    return participants.filter((participant) =>
      participant.id.toLowerCase().includes(query.toLowerCase())
    );
  }, [participants, query]);

  return (
    <section className="users_container">
      <ToastContainer
        position="top-right"
        stacked
        newestOnTop={false}
        autoClose={3000}
      />

      <div className="header" style={{ marginTop: "2rem" }}>
        <select onChange={handleCollectionChange} value={selectedCollection}>
          {collections.map((collection) => (
            <option key={collection.value} value={collection.value}>
              {collection.name}
            </option>
          ))}
        </select>
      </div>

      <div className="reload-search">
        <button onClick={() => fetchData(selectedCollection)}>Reload</button>

        <div className="search">
          Search:&nbsp;&nbsp;
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            type="search"
          />
        </div>
      </div>

      <div className="users">
        {filteredParticipants.map((participant) => (
          <div
            key={participant.id}
            className="user_container"
            style={{
              background:
                participant.role === "staff"
                  ? "blue"
                  : participant.role === "security"
                  ? "black"
                  : participant.role === "personnel"
                  ? "red"
                  : "rgb(0,170,255)",
            }}
          >
            <p style={{ color: "white" }}>{participant.id}</p>
            <div>
              {!participant.checkIn && (
                <button
                  onClick={() => handleChangeStatus(participant.id, "checkIn")}
                >
                  Check In
                </button>
              )}
              {!participant.meal && (
                <button
                  onClick={() => handleChangeStatus(participant.id, "meal")}
                >
                  Meal
                </button>
              )}
              {!participant.checkOut && (
                <button
                  onClick={() => handleChangeStatus(participant.id, "checkOut")}
                >
                  Check Out
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {isLoading && <Loading />}
    </section>
  );
};
