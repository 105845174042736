import "./Admin.css";
import { useState } from "react";
import { toast } from "react-toastify";
import { Loading } from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import { setDocument } from "../../fb/FirebaseFunctions";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import Select from "../../Components/Select";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { Paper, TextField } from "@mui/material";

export const AddData = () => {
  const initialState = {
    collectionName: "",
    id: "",
    attributes: [{ key: "", value: "", type: "string" }],
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(initialState);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const docData = formData.attributes.reduce(
        (acc, { key, value, type }) => {
          if (key.trim() !== "") {
            switch (type) {
              case "string":
                acc[key.trim()] = value.trim();
                break;
              case "number":
                acc[key.trim()] = parseFloat(value.trim());
                break;
              case "boolean":
                acc[key.trim()] = value.trim().toLowerCase() === "true";
                break;
              default:
                acc[key.trim()] = value.trim();
            }
          }
          return acc;
        },
        {}
      );

      setDocument(formData.collectionName, formData.id, docData, setIsLoading);
      toast.success("Data successfully added!");
    } catch (error) {
      toast.error("Error adding data: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAttributeChange = (index, field, value) => {
    const newAttributes = [...formData.attributes];
    newAttributes[index][field] = value;
    setFormData((prev) => ({ ...prev, attributes: newAttributes }));
  };

  const handleAddAttribute = () => {
    setFormData((prev) => ({
      ...prev,
      attributes: [...prev.attributes, { key: "", value: "", type: "string" }],
    }));
  };

  const handleRemoveAttribute = (index) => {
    setFormData((prev) => ({
      ...prev,
      attributes: prev.attributes.filter((_, i) => i !== index),
    }));
  };

  return (
    <section className="users-list centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      <Container w="100%" m="2rem 0 0 0" center>
        <h3>Add Data</h3>
      </Container>

      <Paper
        sx={{ width: "90%", overflow: "hidden", padding: "1rem 0" }}
        className="centered"
      >
        <form onSubmit={handleSubmit} style={{ width: "95%" }}>
          <Container column w="100%" gap="0.7rem">
            <TextField
              type="search"
              name="collectionName"
              value={formData.collectionName}
              onChange={handleInputChange}
              placeholder="Collection Name"
              required
              sx={{ width: "100%" }}
            />
            <TextField
              type="search"
              name="id"
              value={formData.id}
              onChange={handleInputChange}
              placeholder="ID"
              required
              sx={{ width: "100%" }}
            />
            {formData.attributes.map((attribute, index) => (
              <Container w="100%" center gap="0.5rem">
                <TextField
                  type="search"
                  value={attribute.key}
                  onChange={(e) =>
                    handleAttributeChange(index, "key", e.target.value)
                  }
                  placeholder="Key"
                  required
                  style={{ width: "100%" }}
                />
                <TextField
                  type="search"
                  value={attribute.value}
                  onChange={(e) =>
                    handleAttributeChange(index, "value", e.target.value)
                  }
                  placeholder="Value"
                  required
                  style={{ width: "100%" }}
                />
                <Select
                  value={attribute.type}
                  onChange={(e) =>
                    handleAttributeChange(index, "type", e.target.value)
                  }
                  options={[
                    { value: "string", label: "String" },
                    { value: "number", label: "Number" },
                    { value: "boolean", label: "Boolean" },
                  ]}
                />
                <Button
                  pd="0.2rem"
                  variant="error"
                  onClick={() => handleRemoveAttribute(index)}
                >
                  <CloseIcon />
                </Button>
              </Container>
            ))}
            <Button onClick={handleAddAttribute}>Add Attribute</Button>
            <Container w="100%" end>
              <Button variant="success" type="submit">
                <h5>Add Document</h5>
              </Button>
            </Container>
          </Container>
        </form>
      </Paper>

      {isLoading && <Loading />}
    </section>
  );
};
