import { useState } from "react";
import useForm from "../../hooks/useForm";
// Base
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loading } from "../../Components/Loading";
import { toast } from "react-toastify";
// Firebase
import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "firebase/auth";
// Material UI
import TextField from "@mui/material/TextField";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import Link from "../../Components/Link";
import EmailIcon from "@mui/icons-material/Email";
// CSS
import "./Auth.css";

const validateInput = (formData, t) => {
  const { email } = formData;
  const emailRegex = /\S+@\S+\.\S+/;

  if (!emailRegex.test(email)) {
    toast.error(t("reset_password.invalid-email"));
    return false;
  }

  return true;
};

export const ResetPassword = () => {
  const { t } = useTranslation("common");
  const auth = getAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  
  const initialState = { email: "" };
  const [formData, handleChange] = useForm(initialState);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInput(formData, t)) {
      return;
    }

    setIsLoading(true);

    try {
      sendPasswordResetEmail(auth, formData.email)
        .then(() => {
          toast.success('Password Reset Email Sent!');
        })
        .catch((error) => {
          console.log("error ===>", error);    

          if (error.message === "Firebase: Error (auth/user-not-found).") {
            toast.error("There is no user corresponding to this email address.")
          }

          else if (errorCode == 'auth/invalid-email') {
            toast.error(errorMessage)
          } 
        }
      );
    } catch (error) {
      console.error("Error sending password reset email:", error);
      toast.error(t("reset_password.failed"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="auth centered">
      <form onSubmit={handleSubmit} className="centered column">
        <Container center column color="black" w="80%" gap="1rem">
          <h3>{t("reset_password.header")}</h3>

          <Container end w="100%">
            <TextField
              name="email"
              label={t("general.email")}
              variant="standard"
              value={formData.email}
              onChange={handleChange}
              style={{ width: "100%" }}
              InputProps={{
                endAdornment: <EmailIcon sx={{ color: "rgb(0, 44, 74)", mr: 1 }} />,
              }}
            />
          </Container>

          <Button type="submit" w="100%" pd="0.75rem">
            {t("reset_password.reset-btn")}
          </Button>

          <Container center color="black" w="100%" gap="0.2rem">
            <strong>{t("reset_password.account")}</strong>
            <Link inPage href="/login">{t("reset_password.login-btn")}</Link>
          </Container>
        </Container>
      </form>
      {isLoading && <Loading />}
    </section>
  );
};
