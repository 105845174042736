import "react-device-frameset/styles/marvel-devices.min.css";
import Container from "../Components/Container";
import { useTranslation } from 'react-i18next';

import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";

export const AboutUs = () => {
  const {t} = useTranslation('common');

  return (
    <Container bg="white" w="100%" center column gap="3rem" pd="4rem 0">

      <Container w="90%" gap="3rem" className="row-column">

        <Container m="0 0 5% 10%" sx={{ position: "relative", flex: 1 }}>
          <img
            src="/images/devices/iphone.png"
            style={{
              position: "absolute",
              width: "35%",
              bottom: "-15%",
              left: "-15%",
            }}
          />
          <img src="/images/devices/ipad.png" style={{ width: "100%" }} />
        </Container>

        <Container column gap="0.7rem" color="#006BB3" sx={{ flex: 1 }}>
          <h1>
            {t('aboutUs.header')}
            <br/>
            {t('aboutUs.team')}
          </h1>

          <h4 style={{ lineHeight: "180%" }}>{t('aboutUs.subHeading')}</h4>

          <p style={{ lineHeight: "180%" }}>{t('aboutUs.description')}</p>
        </Container>

      </Container>

      <Container w="100%" gap="2rem" center column color="#006BB3" id="values">

      <h1>{t('aboutUs.valuesHeading')}</h1>

        <Container w="90%" gap="2rem"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(17rem, 1fr))",
          }}
        >
          {values.map((value, index) => {
            return (
              <Container column gap="1rem" pd="0 2rem" center color="#006BB3" key={index}>
                {value.icon}

                <h3 style={{ color: "black", textAlign: "center" }}>
                  {t(`aboutUs.values.${index}.title`)}
                </h3>

                <h5 style={{ textAlign: "center", lineHeight: "150%" }}>
                  {t(`aboutUs.values.${index}.description`)}
                </h5>
              </Container>
            );
          })}
        </Container>

      </Container>

    </Container>
  );
};

const values = [
  {icon: <ShowChartOutlinedIcon sx={{ fontSize: "7rem" }} />},
  {icon: <SupportAgentOutlinedIcon sx={{ fontSize: "7rem" }} />},
  {icon: <RocketLaunchOutlinedIcon sx={{ fontSize: "7rem" }} />},
];
