import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CountryCodeSelector = ({ value, onChange, onMaskChange }) => {  
  const handleChange = (event) => {
    const selectedCode = event.target.value;
    onChange(event);
    const selectedCountry = countryCodes.find(country => country.code === selectedCode);
    if (selectedCountry) {
      onMaskChange(selectedCountry.mask);
    }
  };

  return (
    <Select
      name='countryCode'
      value={value}
      onChange={handleChange}
      variant="standard"
      style={{ width: 'fit-content', marginRight: '0.5rem' }}
      renderValue={(selected) => {
        const selectedCountry = countryCodes.find(country => country.code === selected);
        return selectedCountry ? `${selectedCountry.flag} ${selectedCountry.code}` : selected;
      }}
    >
      {countryCodes.map((country) => (
        <MenuItem key={`${country.code}${country.name}`} value={country.code}>
          <p>{country.flag} {country.name} ({country.code})</p>
        </MenuItem>
      ))}
    </Select>
  );
};

export const countryCodes = [
  { code: '+93', name: 'Afghanistan', flag: '🇦🇫', mask: '00 00 000 0000' },
  { code: '+355', name: 'Albania', flag: '🇦🇱', mask: '000 000 0000' },
  { code: '+213', name: 'Algeria', flag: '🇩🇿', mask: '000 00 00 00' },
  { code: '+376', name: 'Andorra', flag: '🇦🇩', mask: '000 000' },
  { code: '+244', name: 'Angola', flag: '🇦🇴', mask: '000 000 000' },
  { code: '+54', name: 'Argentina', flag: '🇦🇷', mask: '0 000 000 0000' },
  { code: '+61', name: 'Australia', flag: '🇦🇺', mask: '0000 000 000' },
  { code: '+43', name: 'Austria', flag: '🇦🇹', mask: '0000 000000' },
  { code: '+994', name: 'Azerbaijan', flag: '🇦🇿', mask: '00 000 00 00' },
  { code: '+973', name: 'Bahrain', flag: '🇧🇭', mask: '0000 0000' },
  { code: '+880', name: 'Bangladesh', flag: '🇧🇩', mask: '0000 000000' },
  { code: '+375', name: 'Belarus', flag: '🇧🇾', mask: '00 000 00 00' },
  { code: '+32', name: 'Belgium', flag: '🇧🇪', mask: '000 00 00 00' },
  { code: '+501', name: 'Belize', flag: '🇧🇿', mask: '000 0000' },
  { code: '+229', name: 'Benin', flag: '🇧🇯', mask: '00 00 00 00' },
  { code: '+975', name: 'Bhutan', flag: '🇧🇹', mask: '000 000 000' },
  { code: '+591', name: 'Bolivia', flag: '🇧🇴', mask: '000 00000' },
  { code: '+387', name: 'Bosnia and Herzegovina', flag: '🇧🇦', mask: '00 000 000' },
  { code: '+267', name: 'Botswana', flag: '🇧🇼', mask: '00 000 000' },
  { code: '+55', name: 'Brazil', flag: '🇧🇷', mask: '00 00000 0000' },
  { code: '+673', name: 'Brunei', flag: '🇧🇳', mask: '000 0000' },
  { code: '+359', name: 'Bulgaria', flag: '🇧🇬', mask: '000 000 000' },
  { code: '+226', name: 'Burkina Faso', flag: '🇧🇫', mask: '00 00 00 00' },
  { code: '+257', name: 'Burundi', flag: '🇧🇮', mask: '00 00 00 00' },
  { code: '+855', name: 'Cambodia', flag: '🇰🇭', mask: '000 000 000' },
  { code: '+237', name: 'Cameroon', flag: '🇨🇲', mask: '0000 000 000' },
  { code: '+238', name: 'Cape Verde', flag: '🇨🇻', mask: '000 00 00' },
  { code: '+236', name: 'Central African Republic', flag: '🇨🇫', mask: '00 00 00 00' },
  { code: '+235', name: 'Chad', flag: '🇹🇩', mask: '00 00 00 00' },
  { code: '+56', name: 'Chile', flag: '🇨🇱', mask: '0 0000 0000' },
  { code: '+86', name: 'China', flag: '🇨🇳', mask: '000 0000 0000' },
  { code: '+57', name: 'Colombia', flag: '🇨🇴', mask: '000 0000000' },
  { code: '+269', name: 'Comoros', flag: '🇰🇲', mask: '000 0000' },
  { code: '+242', name: 'Congo', flag: '🇨🇬', mask: '00 000 0000' },
  { code: '+682', name: 'Cook Islands', flag: '🇨🇰', mask: '00 000' },
  { code: '+506', name: 'Costa Rica', flag: '🇨🇷', mask: '0000 0000' },
  { code: '+385', name: 'Croatia', flag: '🇭🇷', mask: '00 000 000' },
  { code: '+53', name: 'Cuba', flag: '🇨🇺', mask: '0 0000 0000' },
  { code: '+357', name: 'Cyprus', flag: '🇨🇾', mask: '00 000 000' },
  { code: '+420', name: 'Czech Republic', flag: '🇨🇿', mask: '000 000 000' },
  { code: '+45', name: 'Denmark', flag: '🇩🇰', mask: '00 00 00 00' },
  { code: '+253', name: 'Djibouti', flag: '🇩🇯', mask: '00 00 00 00' },
  { code: '+670', name: 'East Timor', flag: '🇹🇱', mask: '000 0000' },
  { code: '+593', name: 'Ecuador', flag: '🇪🇨', mask: '00 000 0000' },
  { code: '+20', name: 'Egypt', flag: '🇪🇬', mask: '000 000 0000' },
  { code: '+503', name: 'El Salvador', flag: '🇸🇻', mask: '0000 0000' },
  { code: '+240', name: 'Equatorial Guinea', flag: '🇬🇶', mask: '000 000 000' },
  { code: '+291', name: 'Eritrea', flag: '🇪🇷', mask: '0 000 000' },
  { code: '+372', name: 'Estonia', flag: '🇪🇪', mask: '0000 0000' },
  { code: '+251', name: 'Ethiopia', flag: '🇪🇹', mask: '00 000 0000' },
  { code: '+679', name: 'Fiji', flag: '🇫🇯', mask: '000 0000' },
  { code: '+358', name: 'Finland', flag: '🇫🇮', mask: '000 0000000' },
  { code: '+33', name: 'France', flag: '🇫🇷', mask: '0 00 00 00 00' },
  { code: '+241', name: 'Gabon', flag: '🇬🇦', mask: '00 00 00 00' },
  { code: '+220', name: 'Gambia', flag: '🇬🇲', mask: '000 0000' },
  { code: '+995', name: 'Georgia', flag: '🇬🇪', mask: '000 000000' },
  { code: '+49', name: 'Germany', flag: '🇩🇪', mask: '000 0000000' },
  { code: '+233', name: 'Ghana', flag: '🇬🇭', mask: '000 000 000' },
  { code: '+30', name: 'Greece', flag: '🇬🇷', mask: '000 000 0000' },
  { code: '+299', name: 'Greenland', flag: '🇬🇱', mask: '00 00 00' },
  { code: '+502', name: 'Guatemala', flag: '🇬🇹', mask: '0000 0000' },
  { code: '+224', name: 'Guinea', flag: '🇬🇳', mask: '00 000 000' },
  { code: '+245', name: 'Guinea-Bissau', flag: '🇬🇼', mask: '00 000000' },
  { code: '+592', name: 'Guyana', flag: '🇬🇾', mask: '000 0000' },
  { code: '+509', name: 'Haiti', flag: '🇭🇹', mask: '00 00 0000' },
  { code: '+504', name: 'Honduras', flag: '🇭🇳', mask: '0000 0000' },
  { code: '+852', name: 'Hong Kong', flag: '🇭🇰', mask: '0000 0000' },
  { code: '+36', name: 'Hungary', flag: '🇭🇺', mask: '00 000 0000' },
  { code: '+354', name: 'Iceland', flag: '🇮🇸', mask: '000 0000' },
  { code: '+91', name: 'India', flag: '🇮🇳', mask: '00000 00000' },
  { code: '+62', name: 'Indonesia', flag: '🇮🇩', mask: '0000 000 000' },
  { code: '+98', name: 'Iran', flag: '🇮🇷', mask: '000 000 0000' },
  { code: '+964', name: 'Iraq', flag: '🇮🇶', mask: '00 000 00000' },
  { code: '+353', name: 'Ireland', flag: '🇮🇪', mask: '000 000 0000' },
  { code: '+972', name: 'Israel', flag: '🇮🇱', mask: '00 000 0000' },
  { code: '+39', name: 'Italy', flag: '🇮🇹', mask: '000 000 0000' },
  { code: '+225', name: 'Ivory Coast', flag: '🇨🇮', mask: '00 00 00 00' },
  { code: '+81', name: 'Japan', flag: '🇯🇵', mask: '00 0000 0000' },
  { code: '+962', name: 'Jordan', flag: '🇯🇴', mask: '00 0000 0000' },
  { code: '+7', name: 'Kazakhstan', flag: '🇰🇿', mask: '000 000 0000' },
  { code: '+254', name: 'Kenya', flag: '🇰🇪', mask: '000 000000' },
  { code: '+686', name: 'Kiribati', flag: '🇰🇮', mask: '00 000' },
  { code: '+965', name: 'Kuwait', flag: '🇰🇼', mask: '000 00000' },
  { code: '+996', name: 'Kyrgyzstan', flag: '🇰🇬', mask: '000 000 000' },
  { code: '+856', name: 'Laos', flag: '🇱🇦', mask: '00 000 000' },
  { code: '+371', name: 'Latvia', flag: '🇱🇻', mask: '000 00000' },
  { code: '+961', name: 'Lebanon', flag: '🇱🇧', mask: '00 000 000' },
  { code: '+266', name: 'Lesotho', flag: '🇱🇸', mask: '00 000 000' },
  { code: '+231', name: 'Liberia', flag: '🇱🇷', mask: '00 000 000' },
  { code: '+218', name: 'Libya', flag: '🇱🇾', mask: '00 000 0000' },
  { code: '+423', name: 'Liechtenstein', flag: '🇱🇮', mask: '000 000 000' },
  { code: '+370', name: 'Lithuania', flag: '🇱🇹', mask: '000 00 0000' },
  { code: '+352', name: 'Luxembourg', flag: '🇱🇺', mask: '000 000 000' },
  { code: '+853', name: 'Macau', flag: '🇲🇴', mask: '0000 0000' },
  { code: '+389', name: 'Macedonia', flag: '🇲🇰', mask: '00 000 000' },
  { code: '+261', name: 'Madagascar', flag: '🇲🇬', mask: '00 00 000 00' },
  { code: '+265', name: 'Malawi', flag: '🇲🇼', mask: '0000 000 000' },
  { code: '+60', name: 'Malaysia', flag: '🇲🇾', mask: '00 000 0000' },
  { code: '+960', name: 'Maldives', flag: '🇲🇻', mask: '000 0000' },
  { code: '+223', name: 'Mali', flag: '🇲🇱', mask: '00 00 00 00' },
  { code: '+356', name: 'Malta', flag: '🇲🇹', mask: '0000 0000' },
  { code: '+692', name: 'Marshall Islands', flag: '🇲🇭', mask: '000 0000' },
  { code: '+222', name: 'Mauritania', flag: '🇲🇷', mask: '00 00 00 00' },
  { code: '+230', name: 'Mauritius', flag: '🇲🇺', mask: '000 0000' },
  { code: '+52', name: 'Mexico', flag: '🇲🇽', mask: '000 000 0000' },
  { code: '+691', name: 'Micronesia', flag: '🇫🇲', mask: '000 0000' },
  { code: '+373', name: 'Moldova', flag: '🇲🇩', mask: '000 000 000' },
  { code: '+377', name: 'Monaco', flag: '🇲🇨', mask: '00 000 000' },
  { code: '+976', name: 'Mongolia', flag: '🇲🇳', mask: '00 00 0000' },
  { code: '+382', name: 'Montenegro', flag: '🇲🇪', mask: '00 000 000' },
  { code: '+212', name: 'Morocco', flag: '🇲🇦', mask: '00 0000 000' },
  { code: '+258', name: 'Mozambique', flag: '🇲🇿', mask: '00 000 0000' },
  { code: '+264', name: 'Namibia', flag: '🇳🇦', mask: '00 000 000' },
  { code: '+674', name: 'Nauru', flag: '🇳🇷', mask: '000 0000' },
  { code: '+977', name: 'Nepal', flag: '🇳🇵', mask: '000 0000000' },
  { code: '+31', name: 'Netherlands', flag: '🇳🇱', mask: '00 000 0000' },
  { code: '+687', name: 'New Caledonia', flag: '🇳🇨', mask: '00 00 00' },
  { code: '+64', name: 'New Zealand', flag: '🇳🇿', mask: '000 000 0000' },
  { code: '+505', name: 'Nicaragua', flag: '🇳🇮', mask: '0000 0000' },
  { code: '+227', name: 'Niger', flag: '🇳🇪', mask: '00 00 00 00' },
  { code: '+234', name: 'Nigeria', flag: '🇳🇬', mask: '000 000 0000' },
  { code: '+683', name: 'Niue', flag: '🇳🇺', mask: '000 0000' },
  { code: '+850', name: 'North Korea', flag: '🇰🇵', mask: '00 000 0000' },
  { code: '+47', name: 'Norway', flag: '🇳🇴', mask: '000 00 000' },
  { code: '+968', name: 'Oman', flag: '🇴🇲', mask: '000 00000' },
  { code: '+92', name: 'Pakistan', flag: '🇵🇰', mask: '000 0000000' },
  { code: '+680', name: 'Palau', flag: '🇵🇼', mask: '000 0000' },
  { code: '+507', name: 'Panama', flag: '🇵🇦', mask: '000 0000' },
  { code: '+675', name: 'Papua New Guinea', flag: '🇵🇬', mask: '000 0000' },
  { code: '+595', name: 'Paraguay', flag: '🇵🇾', mask: '0000 000000' },
  { code: '+51', name: 'Peru', flag: '🇵🇪', mask: '000 000 000' },
  { code: '+63', name: 'Philippines', flag: '🇵🇭', mask: '0000 000 0000' },
  { code: '+48', name: 'Poland', flag: '🇵🇱', mask: '000 000 000' },
  { code: '+351', name: 'Portugal', flag: '🇵🇹', mask: '000 000 000' },
  { code: '+974', name: 'Qatar', flag: '🇶🇦', mask: '0000 0000' },
  { code: '+242', name: 'Republic of the Congo', flag: '🇨🇬', mask: '000 000 000' },
  { code: '+262', name: 'Reunion', flag: '🇷🇪', mask: '000 00 00 00' },
  { code: '+40', name: 'Romania', flag: '🇷🇴', mask: '000 000 000' },
  { code: '+7', name: 'Russia', flag: '🇷🇺', mask: '000 000 0000' },
  { code: '+250', name: 'Rwanda', flag: '🇷🇼', mask: '0000 00000' },
  { code: '+685', name: 'Samoa', flag: '🇼🇸', mask: '00000' },
  { code: '+378', name: 'San Marino', flag: '🇸🇲', mask: '000 0000' },
  { code: '+239', name: 'Sao Tome and Principe', flag: '🇸🇹', mask: '00 000000' },
  { code: '+966', name: 'Saudi Arabia', flag: '🇸🇦', mask: '000 000 0000' },
  { code: '+221', name: 'Senegal', flag: '🇸🇳', mask: '00 000 0000' },
  { code: '+381', name: 'Serbia', flag: '🇷🇸', mask: '00 000 000' },
  { code: '+248', name: 'Seychelles', flag: '🇸🇨', mask: '0 000 000' },
  { code: '+232', name: 'Sierra Leone', flag: '🇸🇱', mask: '000 000000' },
  { code: '+65', name: 'Singapore', flag: '🇸🇬', mask: '0000 0000' },
  { code: '+421', name: 'Slovakia', flag: '🇸🇰', mask: '000 000 000' },
  { code: '+386', name: 'Slovenia', flag: '🇸🇮', mask: '00 000 000' },
  { code: '+677', name: 'Solomon Islands', flag: '🇸🇧', mask: '00000' },
  { code: '+252', name: 'Somalia', flag: '🇸🇴', mask: '00 000000' },
  { code: '+27', name: 'South Africa', flag: '🇿🇦', mask: '00 000 0000' },
  { code: '+82', name: 'South Korea', flag: '🇰🇷', mask: '00 0000 0000' },
  { code: '+34', name: 'Spain', flag: '🇪🇸', mask: '000 00 00 00' },
  { code: '+94', name: 'Sri Lanka', flag: '🇱🇰', mask: '00 000 0000' },
  { code: '+249', name: 'Sudan', flag: '🇸🇩', mask: '00 000 0000' },
  { code: '+597', name: 'Suriname', flag: '🇸🇷', mask: '000 0000' },
  { code: '+268', name: 'Swaziland', flag: '🇸🇿', mask: '00 00 0000' },
  { code: '+46', name: 'Sweden', flag: '🇸🇪', mask: '000 000 0000' },
  { code: '+41', name: 'Switzerland', flag: '🇨🇭', mask: '00 000 00 00' },
  { code: '+963', name: 'Syria', flag: '🇸🇾', mask: '00 000 0000' },
  { code: '+886', name: 'Taiwan', flag: '🇹🇼', mask: '000 0000 000' },
  { code: '+992', name: 'Tajikistan', flag: '🇹🇯', mask: '000 000 000' },
  { code: '+255', name: 'Tanzania', flag: '🇹🇿', mask: '000 000 0000' },
  { code: '+66', name: 'Thailand', flag: '🇹🇭', mask: '000 000 0000' },
  { code: '+228', name: 'Togo', flag: '🇹🇬', mask: '00 000 000' },
  { code: '+676', name: 'Tonga', flag: '🇹🇴', mask: '00 000' },
  { code: '+216', name: 'Tunisia', flag: '🇹🇳', mask: '00 000 000' },
  { code: '+90', name: 'Turkey', flag: '🇹🇷', mask: '000 000 00 00' },
  { code: '+993', name: 'Turkmenistan', flag: '🇹🇲', mask: '00 000 000' },
  { code: '+688', name: 'Tuvalu', flag: '🇹🇻', mask: '0000' },
  { code: '+256', name: 'Uganda', flag: '🇺🇬', mask: '000 000000' },
  { code: '+380', name: 'Ukraine', flag: '🇺🇦', mask: '000 000 0000' },
  { code: '+971', name: 'United Arab Emirates', flag: '🇦🇪', mask: '00 000 0000' },
  { code: '+44', name: 'United Kingdom', flag: '🇬🇧', mask: '00000 000000' },
  { code: '+1', name: 'United States', flag: '🇺🇸', mask: '(000) 000-0000' },
  { code: '+598', name: 'Uruguay', flag: '🇺🇾', mask: '0000 0000' },
  { code: '+998', name: 'Uzbekistan', flag: '🇺🇿', mask: '00 000 0000' },
  { code: '+678', name: 'Vanuatu', flag: '🇻🇺', mask: '00 00000' },
  { code: '+58', name: 'Venezuela', flag: '🇻🇪', mask: '0000 000000' },
  { code: '+84', name: 'Vietnam', flag: '🇻🇳', mask: '00 000 0000' },
  { code: '+967', name: 'Yemen', flag: '🇾🇪', mask: '000 000 000' },
  { code: '+260', name: 'Zambia', flag: '🇿🇲', mask: '000 0000000' },
  { code: '+263', name: 'Zimbabwe', flag: '🇿🇼', mask: '000 000 000' },
];

export default CountryCodeSelector;