import { useState } from 'react';

const useForm = (initialState) => {
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === 'checkbox' ? checked : (typeof value === 'string' && name !== 'message' ? value.trim() : value);

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const updateFormData = (updates) => {
    setFormData(prevData => ({
      ...prevData,
      ...updates,
    }));
  };

  return [formData, handleChange, updateFormData];
};

export default useForm;
