import React, { useState } from 'react';

const Button = ({ 
  onClick, 
  children, 
  disabled = false, 
  variant = 'primary', 
  pd = '0.5rem 0.7rem', 
  m = '0', 
  w = 'fit-content', 
  type = 'button', 
  sx,
  isLoading = false,
  shadow = false
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const baseStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    width: w,
    padding: pd,
    margin: m,
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: '0.3s ease',
    boxShadow: shadow? '0 0 1.9rem rgba(0, 0, 0, 0.5)' : 'none'
  };

  const variants = {
    primary: {
      backgroundColor: isHovered ? '#0056b3' : '#007bff',
      color: 'white',
    },
    secondary: {
      backgroundColor: isHovered ? '#192f4f' : '#1e3554',
      color: 'white',
    },
    ordinary: {
      backgroundColor: isHovered ? '#dedede' : 'white',
      color: 'black',
    },
    blue: {
      backgroundColor: isHovered ? 'rgba(65, 189, 255, 0.05)' : 'transparent',
      color: 'rgb(65, 189, 255)',
    },
    modern: {
      backgroundColor: isHovered ? 'rgb(47, 182, 253)' : 'white',
      color: 'rgb(0, 14, 44)',
    },
    success: {
      backgroundColor: isHovered ? '#218838' : '#28a745',
      color: 'white',
    },
    error: {
      backgroundColor: isHovered ? '#ab2935' : '#dc3545',
      color: 'white',
    }
  };

  const style = {
    ...baseStyles,
    ...variants[variant],
    opacity: disabled || isLoading ? 0.6 : 1,
    cursor: disabled || isLoading ? 'not-allowed' : 'pointer',
  };

  const handleClick = (e) => {
    if (!isLoading && !disabled && onClick) {
      onClick(e);
    }
  };

  return (
    <button
      type={type}
      style={{...style, ...sx}}
      onClick={handleClick}
      disabled={disabled || isLoading}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isLoading ? (
        <>
          <span className="loading-spinner"></span>
          Loading...
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;