import { useState, useEffect } from 'react';
import { auth, db } from '../fb';
import { collection, query, where, getDocs } from 'firebase/firestore';

const useCurrentUser = () => {
    const [userData, setUserData] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [userError, setUserError] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
            try {
            const userRef = query(
                collection(db, 'users'),
                where('email', '==', user.email)
            );
            const snapshot = await getDocs(userRef);
            if (!snapshot.empty) {
                const userData = snapshot.docs.map((doc) => ({
                ...doc.data(),
                }))[0];
                setUserData(userData);
            } else {
                setUserError('No user data found');
            }
            } catch (error) {
                setUserError('Error fetching user data: ' + error.message);
            }
        } else {
            setUserData(null);
        }
        setUserLoading(false);
        });

        return () => unsubscribe();
    }, []);

    return [ userData, userLoading, userError ];
};

export default useCurrentUser;