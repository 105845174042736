import { useCallback } from 'react';

const base64UrlEncode = (input) => {
    const utf8Encoder = new TextEncoder();
    const byteArray = utf8Encoder.encode(input);
    let base64String = btoa(String.fromCharCode(...byteArray));
    return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const base64UrlDecode = (input) => {
    input = input.replace(/-/g, '+').replace(/_/g, '/');
    while (input.length % 4) {
        input += '=';
    }
    const byteString = atob(input);
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
    }
    const utf8Decoder = new TextDecoder();
    return utf8Decoder.decode(byteArray);
};

export const useEncoder = () => {
    return useCallback((input) => base64UrlEncode(input), []);
};

export const useDecoder = () => {
    return useCallback((input) => base64UrlDecode(input), []);
};
