// import { jsPDF } from 'jspdf';

// export const generatePDF = (user, qrCodeDataUrl) => {
//     const doc = new jsPDF();
    
//     // Add title
//     doc.setFontSize(20);
//     doc.text('Event Information', 105, 20, { align: 'center' });

//     // Add user information
//     doc.setFontSize(12);
//     doc.text(`Name: ${user.name} ${user.surname}`, 20, 40);
//     doc.text(`Email: ${user.email}`, 20, 50);
//     doc.text(`Code: ${user.code}`, 20, 60);
//     if (user.tableNumber) {
//         doc.text(`Table Number: ${user.tableNumber}`, 20, 70);
//     }

//     // Add QR code
//     doc.addImage(qrCodeDataUrl, 'PNG', 70, 80, 70, 70);

//     return doc.output('datauristring');
// };

import { setDocument } from "../fb/FirebaseFunctions";

export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
};

export const addRandomGuests = async (collection, numberOfUsers) => {
    let num = 1;
    while (num <= numberOfUsers) {
        const guestID = `${generateRandomString(3)}-${generateRandomString(3)}-${generateRandomString(3)}-${generateRandomString(4)}`
        setDocument(collection, guestID, {
            guestNumber: num,
            marked: false
        }, setIsLoading)
        num++;
    }
}