import React, { useState } from "react";
import { Loading } from "../Components/Loading";
import useFirestoreCollection from "../hooks/useFirestoreCollection";
import useEmail from "../hooks/useEmail";
import Button from "../Components/Button";
import Container from "../Components/Container";
import { toast } from "react-toastify";
import { sendPdfTicket, TicketComponent } from "../Components/TicketComponent";

export const BirthdayEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const users = useFirestoreCollection("users", setIsLoading);

  const timeZoneOffsetHours = 4;
  // Function to check if today is the user's birthday
  const isTodayBirthday = (dob, timeZoneOffsetHours) => {
    const [day, month] = dob.split("/");
    const today = new Date();

    // Adjust the today date based on the time zone offset
    today.setHours(today.getHours() + timeZoneOffsetHours);

    return (
      today.getDate() === parseInt(day) &&
      today.getMonth() + 1 === parseInt(month)
    );
  };

  const sendBirthdayEmail = async () => {
    setIsLoading(true);

    try {
      const birthdayPromises = [];

      users.forEach((user) => {
        if (user.dob && isTodayBirthday(user.dob, timeZoneOffsetHours)) {
          const templateParams = {
            user_email: user.email,
            subject: "Happy Birthday!",
            message: `Dear ${user.name} ${user.surname},
            
                      🎉 Happiest Birthday Wishes from Your Fiesta Family! 🎉
                      
                      On this special day, we want to take a moment to send you our warmest and most heartfelt wishes. May this year be filled with joy, success, and incredible achievements that bring you closer to your dreams. ✨
                      
                      As part of the Fiesta Team, we are beyond excited to celebrate this wonderful milestone with you. Here's to many more shared moments of happiness, laughter, and success in the future! 🎂🥳
                      
                      Enjoy every moment of your special day to the fullest—today is all about YOU! 💫
                      
                      Warmest regards,
                      Your Fiesta Family`,

            from_name: "Fiesta Event Team",
            from_email: "fiesta.helpcenter.az@gmail.com",
            reply_to: "fiesta.helpcenter.az@gmail.com",
          };

          birthdayPromises.push(
            useEmail("template_qs86jrb", templateParams, setIsLoading, false)
          );
        }
      });

      // Wait for all emails to be sent
      await Promise.all(birthdayPromises);

      toast.success(`Sent ${birthdayPromises.length} birthday emails`);
    } catch (error) {
      console.error("Error fetching users or sending emails:", error);
      toast.error("Failed to send birthday emails");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section>
      <Container w="100%" h="100vh" center>
        <Button onClick={sendBirthdayEmail} children={<p>Send Email</p>} />
      </Container>

      <TicketComponent />

      <Button
        onClick={() => {
          sendPdfTicket(
            "Random Person",
            "kgurbanli15744@ada.edu.az",
            "Fiesta Event",
            "123-456-789-8",
          );
          toast.success("PDF ticket created and sent");
        }}
        children={<p>Send pdf</p>}
      />

      {isLoading && <Loading />}
    </section>
  );
};
