import { doc, setDoc, updateDoc, deleteField, deleteDoc } from 'firebase/firestore';
import { ref, deleteObject } from "firebase/storage";
import { storage } from './index';
import { db } from './index';

export const setDocument = async(collection, id, attributes, setIsLoading) => {
    setIsLoading(true);
    await setDoc(doc(db, collection, id), attributes);
    setIsLoading(false);
}

export const updateDocument = async(collection, id, attributes, setIsLoading) => {
    setIsLoading(true);
    await updateDoc(doc(db, collection, id), attributes);
    setIsLoading(false);
}

export const deleteAttribute = async(collection, id, field, setIsLoading) => {
    setIsLoading(true);
    await updateDoc(doc(db, collection, id), {
      [field]: deleteField()
    });
    setIsLoading(false);
}

export const deleteDocument = async(collection, id, setIsLoading) => {
    setIsLoading(true);
    await deleteDoc(doc(db, collection, id));
    setIsLoading(false);
}

export const deleteFile= async(fileRef, setIsLoading) => {
    setIsLoading(true);
    const storageRef = ref(storage, fileRef);
    await deleteObject(storageRef);
    setIsLoading(false);

}