import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from '../fb';

const useFirestoreCollection = (collectionName, setIsLoading = () => {}, reloadTrigger) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (!collectionName) {
                return;
            }
            
            setIsLoading(true);
            try {
                const snapshot = await getDocs(collection(db, collectionName));
                const fetchedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setData(fetchedData);
            } catch (error) {
                toast.error(`Error fetching ${collectionName}. Please try again.`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [collectionName, reloadTrigger]);

    return data;
};

export default useFirestoreCollection;