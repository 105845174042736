import { useState } from 'react';

const Select = ({ 
    name,
    value,
    onChange,
    placeholder,
    options,
    disabled = false,
    variant = 'ordinary', 
    pd = '0.3rem 0.5rem',
    m = '0', 
    w = 'fit-content',
    fontSize = 'small',
    sx,
}) => {
    const [isHovered, setIsHovered] = useState(false);

    const baseStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5rem',
        width: w,
        padding: pd,
        margin: m,
        fontSize: fontSize == 'small'? '1rem' : fontSize == 'medium' ? '1.5rem' : fontSize == 'large' ? '2rem' : '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: '0.3s ease-in-out',
    };

    const variants = {
        primary: {
            backgroundColor: isHovered ? '#0056b3' : '#007bff',
            color: 'white',
        },
        secondary: {
            backgroundColor: isHovered ? '#192f4f' : '#1e3554',
            color: 'white',
        },
        ordinary: {
            backgroundColor: isHovered ? 'rgb(230, 230, 230)' : 'white',
            color: 'black',
            border: isHovered ? 'solid black 1px' : 'solid rgba(100, 100, 100, 0.7) 1px'
        },
    };

    const style = {
        ...baseStyles,
        ...variants[variant],
        opacity: disabled? 0.6 : 1,
        cursor: disabled? 'not-allowed' : 'pointer',
    };

    return (
        <select
            name={name}
            value={value}
            onChange={onChange}
            style={{...style, ...sx}}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {placeholder && 
                <option key='initialOption' value=''>{placeholder}</option>
            }

            {options.map((option) => (
                <option
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </select>
  );
};

export default Select;