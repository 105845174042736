import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';

const useEmail = async (templateId, templateParams, setIsLoading = () => {}, isToast = true) => {

    setIsLoading(true);
    try {
        await emailjs.send('service_awq0r4l', templateId, templateParams, process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
        isToast && toast.success('Email sent successfully!');
    } catch (error) {
        isToast && toast.error('Failed to send email.');
    } finally {
        setIsLoading(false);
    }
};

export default useEmail;