import React, { useState, useRef, useEffect } from 'react';
import Container from './Container';
import { useTranslation } from "react-i18next";

const CodeVerification = ({ onCodeChange }) => {
    const {t} = useTranslation("common");
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const inputs = useRef([]);

    useEffect(() => {
        inputs.current[0].focus();
    }, []);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        const newCode = [...code.map((d, idx) => (idx === index ? element.value : d))];
        setCode(newCode);

        onCodeChange(newCode.join(''));

        if (element.nextSibling) {
        element.nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
        inputs.current[index - 1].focus();
        }
    };

    return (
        <Container w='100%' center column color='black' gap='1rem'>
        <h4>{t('verification.header')}</h4>
        <Container gap='0.5rem'>
            {code.map((digit, index) => (
            <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(ref) => (inputs.current[index] = ref)}
                className='code-input'
            />
            ))}
        </Container>
        </Container>
    );
};

export default CodeVerification;
