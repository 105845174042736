import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { useState, useMemo } from "react";
import { Loading } from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
import Button from "../../Components/Button";

import CustomTable from "../../Components/CustomTable";
import Container from "../../Components/Container";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const Users = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const users = useFirestoreCollection("users", setIsLoading);

  return (
    <section className="users-list centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      <Container w="100%" m="2rem 0 0 0" center>
        <h3>Users</h3>
      </Container>

      <CustomTable
        columns={[
          { id: "name", label: "Name" },
          { id: "surname", label: "Surname" },
          { id: "email", label: "Email" },
          { id: "dob", label: "DOB" },
          { id: "phone", label: "Phone" },
        ]}
        rows={users}
      />

      {isLoading && <Loading />}
    </section>
  );
};
