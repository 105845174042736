import { useNavigate } from 'react-router-dom';
import './Admin.css';
import Container from '../../Components/Container';
import Button from '../../Components/Button';

export const Admin = () => {

    const navigate = useNavigate();

    return (
        <section className='users-list centered column'>
            
            <h1>Admin</h1>

            <Container w='90%' column>

                <Container>
                    <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_FEEDBACKS_LINK)}}>Data</Button>
                    <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_USERS_EMAIL_LINK)}}>Users Email</Button>
                    {/* <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_USERS_LINK)}}>Users</Button>
                    <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_JOBS_LINK)}}>Job Applications</Button> */}

                </Container>

                <div className='line'></div>

                <Container>
                    {/* <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_ADD_USERS_LINK)}}>Add Users</Button> */}
                    <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_PAYMENTS_LINK)}}>Payments</Button>
                    <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_ADD_TABLES_LINK)}}>Add Tables</Button>
                    <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_REPORT_LINK)}}>Report</Button>
                </Container>

                <div className='line'></div>

                <Container>
                    <Button variant='ordinary' m='0.5rem' pd='0.5rem' onClick={() => {navigate('/'+process.env.REACT_APP_ADD_DATA_LINK)}}>Add Data</Button>
                </Container>


            </Container>

        </section>
 
    );
}