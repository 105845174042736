import { useState } from 'react';
import { useNavigate } from'react-router-dom';

const Link = ({ href, children, variant = 'primary', inPage=false }) => {

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        navigate(href);
    };

    const variants = {
        primary: {
            color: '007bff',
        },
        media: {
            color: 'rgb(47, 182, 253)',
        },
        footer: {
            color: 'rgb(220, 220, 220)',
        }
      };

    const style = {
        ...variants[variant],
        textDecoration: 'underline',
        transition: '0.3s ease',
        cursor: 'pointer'
    };

    const hoverStyle = {
        ...style,
        opacity: 0.8,
        textDecoration: 'none',
    };

    const [isHovered, setIsHovered] = useState(false);

    const props = {
        onClick: inPage? handleClick : () => {},
        target: inPage? '' : '_blank',
        href: href,
        style: isHovered ? hoverStyle : style,
        onMouseEnter: () => setIsHovered(true),
        onMouseLeave: () => setIsHovered(false),
    };

    return <a {...props}>{children}</a>;
};

export default Link;