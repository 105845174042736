import { useTranslation } from "react-i18next";
import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../fb";
import { setDocument } from "../fb/FirebaseFunctions";
import useCurrentUser from "../hooks/useCurrentUser";
import useForm from "../hooks/useForm";
import useEmail from "../hooks/useEmail";
import { Loading } from "../Components/Loading";
import Button from "../Components/Button";
import Container from "../Components/Container";
import Select from "../Components/Select";
import CountryCodeSelector, {
  countryCodes,
} from "../Components/CountryCodeSelector";

// Material UI Components and Icons
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";

// Material UI Icons
import AccountCircle from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// IMaskInput
import { IMaskInput } from "react-imask";

const validateInput = (
  formData,
  userData,
  file,
  phoneMask,
  ticketOptions,
  t
) => {
  const { name, surname, dob, phone, email, ticket_type } = formData;

  if (!userData) {
    if (
      /\d/.test(name) ||
      /[^a-zA-Z0-9\s]/.test(name) ||
      name.split(" ").length > 1 ||
      name.length < 2
    ) {
      toast.error(t("toast.invalid-name"));
      return false;
    }
    if (
      /\d/.test(surname) ||
      /[^a-zA-Z0-9\s]/.test(surname) ||
      surname.split(" ").length > 1 ||
      surname.length < 2
    ) {
      toast.error(t("error.invalidSurname"));
      return false;
    }
    const dobDay = dob.substring(0, 2);
    const dobMonth = dob.substring(3, 5);
    const dobYear = dob.substring(6);
    const currentYear = new Date().getFullYear();
    if (
      dobDay > 31 ||
      dobMonth > 12 ||
      dobYear <= 1900 ||
      dobYear >= currentYear
    ) {
      toast.error(t("error.invalidDob"));
      return false;
    }

    const cleanedPhone = phone.replace(/\D/g, "");
    const cleanedMask = phoneMask.replace(/\D/g, "");
    if (cleanedPhone.length !== cleanedMask.length) {
      toast.error(t("error.invalidPhone"));
      return false;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      toast.error(t("error.invalidEmail"));
      return false;
    }
  }

  if (ticketOptions && !ticket_type) {
    toast.error(t("error.ticketTypeRequired"));
    return;
  }

  if (file == null) {
    toast.error(t("error.receiptRequired"));
    return;
  }

  return true;
};

const PhoneMask = React.forwardRef(function PhoneMask(props, ref) {
  const { onChange, mask, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const DobMask = React.forwardRef(function DobMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="##/##/####"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const EventPayment = ({
  collection,
  ticketOptions,
  card,
  bank,
  expirationDate,
}) => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [userData] = useCurrentUser();
  const progressToastId = useRef(null);

  const initialState = {
    name: "",
    surname: "",
    phone: "",
    dob: "",
    countryCode: "+994",
    email: "",
    ticket_type: "",
    promoCode: "",
  };

  const [formData, handleChange, updateFormData] = useForm(initialState);
  const [phoneMask, setPhoneMask] = useState("00 000 00 00");
  const [file, setFile] = useState(null);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        toast.error(t("error.fileSizeExceeded"));
        setFile(null);
      } else {
        setFile(selectedFile);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInput(formData, userData, file, phoneMask, ticketOptions, t)) {
      return;
    }

    try {
      const today = new Date();
      const currentTime = today.getTime();
      const storageRef = ref(storage, `payment/${file.name}${currentTime}`);

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setIsLoading(true);
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress);

          if (progressToastId.current === null) {
            progressToastId.current = toast.info(
              `Uploading Receipt: ${Math.round(progress)}%`,
              {
                autoClose: false,
                isLoading: true,
              }
            );
          } else {
            toast.update(progressToastId.current, {
              render: `Uploading Receipt: ${Math.round(progress)}%`,
              type: "info",
              autoClose: false,
              isLoading: true,
            });
          }
        },
        (error) => {
          toast.error("Error uploading a receipt");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setIsLoading(false);

            if (progressToastId.current !== null) {
              toast.update(progressToastId.current, {
                render: "Upload complete!",
                type: "success",
                autoClose: 5000,
                isLoading: false,
              });
              progressToastId.current = null;
            }

            const reversedTimestamp = (9999999999999 - currentTime).toString();
            const dateTime = `${today.toDateString()} ${today.toLocaleTimeString()}`;

            const paymentData = {
              name: userData ? userData.name : formData.name,
              surname: userData ? userData.surname : formData.surname,
              phone: userData
                ? userData.phone
                : `(${formData.countryCode})${formData.phone}`,
              email: userData ? userData.email : formData.email,
              dob: userData ? userData.dob : formData.dob,
              event: collection,
              file_ref: `payment/${file.name}${currentTime}`,
              ticket_type: formData.ticket_type,
              promo_code: formData.promoCode,
              receipt: downloadURL,
              timestamp: dateTime,
            };

            setDocument(
              "payments",
              reversedTimestamp,
              paymentData,
              setIsLoading
            );
          });
        }
      );

      const templateParams = {
        user_email: userData ? userData.email : formData.email,
        subject: "Ticket Payment",
        message: `Dear User,
    
                  Thank you for submitting your payment receipt. We have successfully received it, and our team will review the details within 2-3 business day. Once verified, you’ll receive your ticket via email.

                  If you have any questions or need further assistance, feel free to reach out to us:
                  fiestagroup@ada.edu.az
                  +994107137007
                  
                  Best regards,
                  Fiesta Event Team`,

        from_name: "Fiesta Event Team",
        from_email: "fiesta.helpcenter.az@gmail.com",
        reply_to: "fiesta.helpcenter.az@gmail.com",
      };

      useEmail("template_qs86jrb", templateParams, setIsLoading, false);

      toast.success(t("eventPayment.success"));
      updateFormData(initialState);
      setFile(null);
    } catch (error) {
      toast.error(t("eventPayment.error"));
    }
  };

  const handleCountryCodeChange = (event) => {
    const selectedCode = event.target.value;
    const selectedCountry = countryCodes.find(
      (country) => country.code === selectedCode
    );
    if (selectedCountry) {
      setPhoneMask(selectedCountry.mask);
      updateFormData({
        countryCode: selectedCode,
        phone: "",
      });
    }
  };

  return (
    <Container center w="100%" gap="1rem" m="3rem 0" column>
      <Container
        center
        column
        shadow
        rounded
        w="clamp(16.5rem, 30rem, 85%)"
        pd="2rem 0"
        gap="1rem"
        bg="white"
      >
        <h3 style={{ color: "black" }}>{t("eventPayment.header")}</h3>

        <form onSubmit={handleSubmit} className="centered w100">
          <Container center column w="85%" gap="1rem">
            {!userData && (
              <>
                <Container end w="100%">
                  <TextField
                    name="name"
                    label={t("general.name")}
                    variant="standard"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ marginRight: "0.5rem", width: "100%" }}
                  />
                  <TextField
                    name="surname"
                    label={t("general.surname")}
                    variant="standard"
                    value={formData.surname}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                  <AccountCircle className="icon" sx={{ color: "black" }} />
                </Container>

                <Container end w="100%">
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel htmlFor="dobMask">
                      {t("general.dob")}
                    </InputLabel>
                    <Input
                      value={formData.dob}
                      onChange={handleChange}
                      placeholder="DD/MM/YYYY"
                      name="dob"
                      id="dobMask"
                      inputComponent={DobMask}
                    />
                  </FormControl>
                  <CalendarMonthIcon className="icon" sx={{ color: "black" }} />
                </Container>

                <Container end w="100%">
                  <CountryCodeSelector
                    value={formData.countryCode}
                    onChange={handleCountryCodeChange}
                    onMaskChange={setPhoneMask}
                  />
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel htmlFor="phoneNumber">
                      {t("general.phone")}
                    </InputLabel>
                    <Input
                      value={formData.phone}
                      onChange={handleChange}
                      name="phone"
                      id="phoneNumber"
                      inputComponent={PhoneMask}
                      inputProps={{
                        mask: phoneMask,
                      }}
                    />
                  </FormControl>
                  <PhoneIcon className="icon" sx={{ color: "black" }} />
                </Container>

                <Container end w="100%">
                  <TextField
                    name="email"
                    label={t("general.email")}
                    variant="standard"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                  <EmailIcon className="icon" sx={{ color: "black" }} />
                </Container>
              </>
            )}

            <Container w="100%" column color="black">
              <p>
                <strong>Bank:</strong> {bank}
              </p>
              <p>
                <strong>Card Number:</strong> {card}
              </p>
              <p>
                <strong>Expiration Date:</strong> {expirationDate}
              </p>
            </Container>

            {ticketOptions && (
              <Select
                name="ticket_type"
                value={formData.ticket_type}
                onChange={handleChange}
                variant="ordinary"
                w="100%"
                placeholder={t("eventPayment.selectTicket")}
                options={ticketOptions}
              />
            )}

            <Container end w="95%">
              <TextField
                name="promoCode"
                label={t("general.promoCode")}
                variant="standard"
                value={formData.promoCode}
                onChange={handleChange}
                style={{ width: "100%" }}
              />
            </Container>

            <Container
              w="95%"
              gap="0.2rem"
              sx={{ justifyContent: "space-between" }}
            >
              <label htmlFor="file_upload">
                {t("eventPayment.uploadPayment")}
              </label>
              <input
                type="file"
                id="file_upload"
                name="file"
                accept=".pdf,.doc,.docx,image/*"
                onChange={handleFileChange}
                style={{ opacity: 0, width: 0 }}
              />
              {file && <p style={{ color: "black" }}>{file.name}</p>}
            </Container>

            <Button w="100%" type="submit">
              <p>{t("eventPayment.submitBtn")}</p>
            </Button>
            <Container color="red">
              <span style={{ fontSize: "0.7rem" }}>
                --- Payment is non-refundable ---
              </span>
            </Container>
          </Container>
        </form>

        {isLoading && <Loading />}
      </Container>
    </Container>
  );
};
