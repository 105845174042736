import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../fb'
import { setDocument } from '../fb/FirebaseFunctions';
import useCurrentUser from '../hooks/useCurrentUser';
import useForm from '../hooks/useForm';
import useEmail from '../hooks/useEmail';
import { Loading } from '../Components/Loading';
import Button from '../Components/Button';
import Container from '../Components/Container';
import Select from '../Components/Select';
import CountryCodeSelector, { countryCodes } from '../Components/CountryCodeSelector';

// Material UI Components and Icons
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

// Material UI Icons
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// IMaskInput
import { IMaskInput } from 'react-imask';

const validateInput = (formData, userData, file, phoneMask, t) => {
    const { name, surname, dob, phone, email, position, message } = formData;

    if (!userData) {
        if (/\d/.test(name) ||
            /[^a-zA-Z0-9\s]/.test(name) ||
            name.split(' ').length > 1 ||
            name.length < 2)
        {
            toast.error(t('error.invalidName'));
            return false;
        }
        if (/\d/.test(surname) ||
            /[^a-zA-Z0-9\s]/.test(surname) ||
            surname.split(' ').length > 1 ||
            surname.length < 2)
        {
            toast.error(t('error.invalidSurname'));
            return false;
        }
        const dobDay = dob.substring(0,2);
        const dobMonth = dob.substring(3,5);
        const dobYear = dob.substring(6);
        const currentYear = new Date().getFullYear();
        if (dobDay>31 || dobMonth>12 || dobYear <= 1900 || dobYear >= currentYear) {
            toast.error(t('error.invalidDob'));
            return false;
        }
    
        const cleanedPhone = phone.replace(/\D/g, '');
        const cleanedMask = phoneMask.replace(/\D/g, '');
        if (cleanedPhone.length !== cleanedMask.length) {
            toast.error(t('error.invalidPhone'));
            return false;
        }
    
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(email)) {
            toast.error(t('error.invalidEmail'));
            return false;
        }
    }

    if (!position) {
        toast.error(t('error.positionRequired'));
        return;
    }

    if (!message) {
        toast.error(t('error.messageRequired'));
        return;
    }

    if (file == null) {
        toast.error(t('error.cvRequired'));
        return;
    }

    return true;
};

const PhoneMask = React.forwardRef(function PhoneMask(props, ref) {
    const { onChange, mask, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});

const DobMask = React.forwardRef(function DobMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='##/##/####'
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});


export const FiestaTeam = () => {
    const { t } = useTranslation('common');
    const [isLoading, setIsLoading] = useState(false);
    const [userData] = useCurrentUser();

    const initialState = {
        name: '',
        surname: '',
        phone: '',
        countryCode: '+994',
        email: '',
        position: '',
        message: '',
    };

    const [formData, handleChange, updateFormData] = useForm(initialState);
    const [phoneMask, setPhoneMask] = useState('00 000 00 00');
    const [file, setFile] = useState(null);

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            if (selectedFile.size > MAX_FILE_SIZE) {
                toast.error(t("error.fileSizeExceeded"));
                setFile(null);
            } else {
                setFile(selectedFile);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInput(formData, userData, file, phoneMask, t)) {
            return;
        }

        try {
            const today = new Date();
            const currentTime = today.getTime()
            const storageRef = ref(storage, `jobApplications/${file.name}${currentTime}`);

            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    setIsLoading(true);
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(progress);
                }, 
                (error) => {
                    toast.error('Error uploading a file')
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                        setIsLoading(false);

                        const reversedTimestamp = (9999999999999 - currentTime).toString();
                        const dateTime = `${currentTime.toDateString()} ${currentTime.toLocaleTimeString()}`

                        const applicationData = {
                            name: userData? userData.name : formData.name,
                            surname: userData? userData.surname : formData.surname,
                            phone: userData? userData.phone : `(${formData.countryCode})${formData.phone}`,
                            dob: userData? userData.dob : formData.dob,
                            email: userData? userData.email : formData.email,
                            position: formData.position,
                            message: formData.message,
                            cv: downloadURL,
                            timestamp: dateTime,
                        };

                        setDocument('jobApplications', reversedTimestamp, applicationData, setIsLoading);

                        const templateParams = {
                            subject: "New Job Application",
                            user_email: 'fiestagroup@ada.edu.az',
                            message: `You have a new job application from ${formData.name} ${formData.surname}`,
                            from_name: "Website",
                            from_email: 'fiesta.helpcenter.az@gmail.com',
                            reply_to: 'fiesta.helpcenter.az@gmail.com'
                        };
                      

                        useEmail('template_qs86jrb', templateParams, setIsLoading, false);

                    });
                }
            );

            toast.success(t('jobApplication.success'));
            updateFormData(initialState);
        } catch (error) {
            toast.error(t('jobApplication.error'));
        }
    };

    const handleCountryCodeChange = (event) => {
        const selectedCode = event.target.value;
        const selectedCountry = countryCodes.find(country => country.code === selectedCode);
        if (selectedCountry) {
            setPhoneMask(selectedCountry.mask);
            updateFormData({
                countryCode: selectedCode,
                phone: ''
            });
        }
    };

    return (
        <Container center w='100%' gap='1rem' m="3rem 0" column>
            <Container 
                center
                column
                shadow
                rounded 
                w='clamp(16.5rem, 30rem, 85%)' 
                pd='2rem 0' 
                gap='1rem'
                bg='white'
            >
                <h3 style={{color: 'black'}}>{t('jobApplication.header')}</h3>

                <form onSubmit={handleSubmit} className='centered w100'>
                    <Container center column w='85%' gap='1rem'>
                        { !userData &&
                            <>
                                <Container end w='100%'>
                                    <TextField name='name' label={t('general.name')} variant='standard' value={formData.name} onChange={handleChange} style={{marginRight: '0.5rem', width: '100%'}}/>
                                    <TextField name='surname' label={t('general.surname')} variant='standard' value={formData.surname} onChange={handleChange} style={{width: '100%'}}/>
                                    <AccountCircle className='icon' />
                                </Container>

                                <Container end w='100%'>
                                    <FormControl variant='standard' style={{width: '100%'}}>
                                        <InputLabel htmlFor='dobMask'>{t('general.dob')}</InputLabel>
                                        <Input
                                            value={formData.dob}
                                            onChange={handleChange}
                                            placeholder='DD/MM/YYYY'
                                            name='dob'
                                            id='dobMask'
                                            inputComponent={DobMask}
                                        />
                                    </FormControl>
                                    <CalendarMonthIcon className='icon' />
                                </Container>

                                <Container end w='100%'>
                                    <CountryCodeSelector
                                        value={formData.countryCode}
                                        onChange={handleCountryCodeChange}
                                        onMaskChange={setPhoneMask}
                                    />
                                    <FormControl variant='standard' style={{width: '100%'}}>
                                        <InputLabel htmlFor='phoneNumber'>{t('general.phone')}</InputLabel>
                                        <Input
                                            value={formData.phone}
                                            onChange={handleChange}
                                            name='phone'
                                            id='phoneNumber'
                                            inputComponent={PhoneMask}
                                            inputProps={{
                                                mask: phoneMask
                                            }}
                                        />
                                    </FormControl>
                                    <PhoneIcon className='icon' />
                                </Container>

                                <Container end w='100%'>
                                    <TextField name='email' label={t('general.email')} variant='standard' value={formData.email} onChange={handleChange} style={{width: '100%'}}/>
                                    <EmailIcon className='icon' />
                                </Container>
                            </>
                        }

                        <Select
                            name='position'
                            value={formData.position}
                            onChange={handleChange}
                            variant='ordinary'
                            w='100%'
                            placeholder={t('jobApplication.positionPlaceholder')}
                            options={[
                                { value: 'Academic Coordinator', label: 'Academic Coordinator' },
                                { value: 'Social Media Manager', label: 'Social Media Manager' },
                                { value: 'Face of Fiesta Family', label: 'Face of Fiesta Family' },
                                { value: 'Call Center', label: 'Call Center' },
                            ]}
                        />

                        <Container column w='100%' gap='0.2rem'>
                            <Container color='black' w='100%'>
                                <h5 style={{fontWeight: 600}}>Why You?</h5>
                            </Container>
                            <Container center w='100%'>
                                <textarea
                                    name='message'
                                    placeholder={t('jobApplication.message')}
                                    value={formData.message}
                                    onChange={handleChange}
                                    style={{ minWidth: '10rem', minHeight: '5rem', maxWidth: '100%', width: '100%' }} 
                                />
                            </Container>
                        </Container>

                        <Container w='95%' sx={{justifyContent: 'space-between'}}>

                            <label htmlFor="file_upload">
                                Upload CV
                            </label>
                            <input
                                type='file'
                                id='file_upload'
                                name='cv'
                                accept='.pdf,.doc,.docx'
                                onChange={handleFileChange}
                                style={{ opacity: 0, width: 0 }}
                            />
                            {file && <p style={{color: 'black'}}>{file.name}</p>}
                        </Container>

                        <Button w='100%' type='submit'>
                            <p>{t('jobApplication.submit-btn')}</p>
                        </Button>
                    </Container>
                </form>

                {isLoading && <Loading />}

            </Container>
        </Container>
    );
};