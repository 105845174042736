import "./Admin.css";
import { useState, useMemo, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Loading } from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import { updateDocument } from "../../fb/FirebaseFunctions";
import useEmail from "../../hooks/useEmail";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { useEncoder } from "../../hooks/useEncryption";
import { generateQRCode } from "../../Utils/QrCodeUtils";
import Button from "../../Components/Button";
import Select from "../../Components/Select";
import Container from "../../Components/Container";
import CustomTable from "../../Components/CustomTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const AddTables = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const events = useFirestoreCollection("events", setIsLoading);
  const addedUsers = useFirestoreCollection(
    selectedCollection,
    setIsLoading,
    reloadTrigger
  );

  const collections = useMemo(
    () =>
      events
        .filter((event) => event.isAddTables)
        .map((event) => ({
          name: event.name,
          value: event.value,
          templateId: event.addTables_templateId,
        })),
    [events]
  );

  useEffect(() => {
    if (collections.length > 0) {
      setSelectedCollection(collections[0].value);
      setTemplateId(collections[0].templateId);
    }
  }, [collections]);

  const handleCollectionChange = (event) => {
    const selected = collections.find((c) => c.value === event.target.value);
    setSelectedCollection(selected.value);
    setTemplateId(selected.templateId);
    setTableNumber("");
  };

  const handleReload = useCallback(() => {
    setReloadTrigger((prev) => prev + 1);
  }, []);

  const addTable = useCallback(
    async (user) => {
      if (!tableNumber) {
        toast.error("Please enter a table number.");
        return;
      }

      setIsLoading(true);
      try {

        updateDocument(
          selectedCollection,
          user.email,
          {
            table_num: tableNumber,
          },
          setIsLoading
        );

        toast.success(`Table number added for ${user.name} ${user.surname}!`);

        if (templateId) {
          const templateParams = {
            user_name: user.name,
            user_surname: user.surname,
            user_email: user.email,
            table_num: tableNumber,
          };

          useEmail(templateId, templateParams, setIsLoading);
        }
      } catch (error) {
        toast.error(`Error adding table for ${user.name} ${user.surname}`);
      } finally {
        handleReload();
        setIsLoading(false);
      }
    },
    [selectedCollection, tableNumber, templateId]
  );

  return (
    <section className="users-list centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      <Container sx={{ position: "absolute", top: "1rem", right: "1rem" }}>
        <h5>Add Tables</h5>
      </Container>

      <Container w="100%" m="2rem 0 0 0" center>
        <Select
          onChange={handleCollectionChange}
          value={selectedCollection}
          fontSize="medium"
          options={collections.map((collection) => ({
            value: collection.value,
            label: collection.name,
          }))}
        />
      </Container>

      <CustomTable
        columns={[
          { id: "name", label: "Name" },
          { id: "surname", label: "Surname" },
          { id: "email", label: "Email" },
          { id: "dob", label: "DOB" },
          { id: "phone", label: "Phone" },
          { id: "ticket", label: "Ticket" },
        ]}
        pageName="table"
        rows={addedUsers}
        addRow={addTable}
        status='table_num'
        handleReload={handleReload}
        tableNumber={tableNumber}
        setTableNumber={setTableNumber}
        requiresTableNumber={true}
      />

      {isLoading && <Loading />}
    </section>
  );
};
