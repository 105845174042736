import { PuffLoader } from "react-spinners";

export const Loading = () => {
    const overlayStyle = {
        position: "fixed",
        padding: "0",
        margin: "0",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "rgba(4, 33, 74, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 20
    };

    const textStyle = {
        backgroundImage: "url(./images/logo/no-text.png)",
        backgroundSize: "40% 40%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        animation: "blink 0.8s alternate infinite",
        zIndex: 21
    };

    const keyframesStyle = `
        @keyframes blink {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    `;

    return (
        <>
            <style>{keyframesStyle}</style>
            <div style={overlayStyle}>
                <div style={textStyle}>
                    <PuffLoader size={300} color="white" />
                </div>
            </div>
        </>
    );
};
