import Button from "../../../Components/Button";
import Container from "../../../Components/Container";

export const CreateEvent = () => {
  return (
    <Container w="100%" center column bg='rgb(0, 44, 74)' pd="3rem 0" gap="1.5rem">
      <h1 style={{textAlign: 'center'}}>CREATE <span style={{color: 'rgb(65, 189, 255)'}}>YOUR</span> UNIQUE EVENT!</h1>
      <Button variant="ordinary" children={<h4>View Catalogue</h4>} />
    </Container>
  );
};
