import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./Components/Navbar";
import { StudyEvent } from "./Pages/Home/Components/StudyEvent";
import { Home } from "./Pages/Home";
import { AboutUs } from "./Pages/AboutUs";
import { Portfolio } from "./Pages/Portfolio";
import { ContactUs } from "./Pages/ContactUs";
import { Events } from "./Pages/Events";
import { Feedback } from "./Pages/Feedback";
import { Login } from "./Pages/Auth/Login";
import { Register } from "./Pages/Auth/Register";
import { ResetPassword } from "./Pages/Auth/ResetPassword";
import { Admin } from "./Pages/Admin/Admin";
import { Report } from "./Pages/Admin/Report";
import { AddTables } from "./Pages/Admin/AddTables";
import { AddUsers } from "./Pages/Admin/AddUsers";
import { Users } from "./Pages/Admin/Users";
import { UsersEmail } from "./Pages/Admin/UsersEmail";
import { Feedbacks } from "./Pages/Admin/Feedbacks";
import { JobApplications } from "./Pages/Admin/JobApplications";
import { Payments } from "./Pages/Admin/Payments";
import { AddData } from "./Pages/Admin/AddData";
import { BirthdayEmail } from "./Pages/BirthdayEmail";
import { Reception } from "./Pages/Scanner/Reception";
import { Scanner1 } from "./Pages/Scanner/Scanner1";
import { Scanner2 } from "./Pages/Scanner/Scanner2";
import { Scanner3 } from "./Pages/Scanner/Scanner3";
import { Team } from "./Pages/Team";
import { Footer } from "./Components/Footer";

const routes = {
  "/": { component: Home, hasHeader: true, hasFooter: true },
  "/about": { component: AboutUs, hasHeader: true, hasFooter: true },
  "/portfolio": { component: Portfolio, hasHeader: true, hasFooter: true },
  "/contacts": { component: ContactUs, hasHeader: true, hasFooter: true },
  "/events": { component: Events, hasHeader: true, hasFooter: true },
  "/feedback": { component: Feedback, hasHeader: true, hasFooter: true },
  "/study_event": { component: StudyEvent, hasHeader: true, hasFooter: true },
  "/login": { component: Login, hasHeader: true, hasFooter: true },
  "/register": { component: Register, hasHeader: true, hasFooter: true },
  "/reset_password": {
    component: ResetPassword,
    hasHeader: true,
    hasFooter: true,
  },
  [`/${process.env.REACT_APP_ADMIN_LINK}`]: {
    component: Admin,
    hasHeader: true,
    hasFooter: true,
  },
  [`/${process.env.REACT_APP_USERS_LINK}`]: {
    component: Users,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_USERS_EMAIL_LINK}`]: {
    component: UsersEmail,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_FEEDBACKS_LINK}`]: {
    component: Feedbacks,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_JOBS_LINK}`]: {
    component: JobApplications,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_PAYMENTS_LINK}`]: {
    component: Payments,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_ADD_USERS_LINK}`]: {
    component: AddUsers,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_ADD_TABLES_LINK}`]: {
    component: AddTables,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_REPORT_LINK}`]: {
    component: Report,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_ADD_DATA_LINK}`]: {
    component: AddData,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_BD_EMAIL_LINK}`]: {
    component: BirthdayEmail,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_RECEPTION_LINK}`]: {
    component: Reception,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_SCANNER1_LINK}`]: {
    component: Scanner1,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_SCANNER2_LINK}`]: {
    component: Scanner2,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_SCANNER3_LINK}`]: {
    component: Scanner3,
    hasHeader: false,
    hasFooter: false,
  },
  [`/${process.env.REACT_APP_TEAM_LINK}`]: {
    component: Team,
    hasHeader: true,
    hasFooter: true,
  },
};

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        stacked
        newestOnTop={false}
        autoClose={3000}
      />

      <main>
        <Routes>
          {Object.entries(routes).map(
            ([path, { component: Component, hasHeader, hasFooter }]) => (
              <Route
                key={path}
                path={path}
                element={
                  <>
                    {hasHeader && <Navbar />}
                    <Component />
                    {hasFooter && <Footer />}
                  </>
                }
              />
            )
          )}
          <Route
            path="*"
            element={
              <>
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
