import "./Admin.css";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../Components/Loading";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { setDocument } from "../../fb/FirebaseFunctions";
import useEmail from "../../hooks/useEmail";
import { generateRandomString } from "../../Utils/RandomUtils";
import { generateQRCode } from "../../Utils/QrCodeUtils";
import Button from "../../Components/Button";
import Select from "../../Components/Select";
import Container from "../../Components/Container";
import CustomTable from "../../Components/CustomTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const AddUsers = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCollection, setSelectedCollection] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const events = useFirestoreCollection("events", setIsLoading);
  const users = useFirestoreCollection("users", setIsLoading);

  const addedUsers = useFirestoreCollection(
    selectedCollection,
    setIsLoading,
    reloadTrigger
  );

  const collections = useMemo(
    () =>
      events
        .filter((event) => event.isAddUsers)
        .map((event) => ({
          name: event.name,
          value: event.value,
          templateId: event.addUsers_templateId,
          requiresTableNumber: event.requiresTableNumber,
        })),
    [events]
  );

  useEffect(() => {
    if (collections.length > 0) {
      setSelectedCollection(collections[0].value);
      setTemplateId(collections[0].templateId);
    }
  }, [collections]);

  const handleCollectionChange = (event) => {
    const selected = collections.find((c) => c.value === event.target.value);
    setSelectedCollection(selected.value);
    setTemplateId(selected.templateId);
    setTableNumber("");
  };

  const handleReload = useCallback(() => {
    setReloadTrigger((prev) => prev + 1);
  }, []);

  const addUser = useCallback(
    async (user) => {
      const selectedEvent = collections.find(
        (c) => c.value === selectedCollection
      );
      if (selectedEvent?.requiresTableNumber && !tableNumber) {
        toast.error("Please enter a table number.");
        return;
      }

      setIsLoading(true);
      try {
        const code = generateRandomString(5);

        const QRcodeID = `${generateRandomString(3)}-${generateRandomString(
          3
        )}-${generateRandomString(3)}-${generateRandomString(4)}`;
        const qrCodeDataUrl = generateQRCode(QRcodeID);

        setDocument(
          selectedCollection,
          user.email,
          {
            name: user.name,
            surname: user.surname,
            email: user.email,
            dob: user.dob,
            phone: user.phone,
            payment: "offline",
            code: code,
            qr_code: QRcodeID,
            table_num: "",
          },
          setIsLoading
        );
        toast.success(`${user.name} ${user.surname} is Added!`);

        if (templateId) {
          if (qrCodeDataUrl) {
            const templateParams = {
              user_name: user.name,
              user_surname: user.surname,
              user_email: user.email,
              code: user.code,
              table_num: user.tableNumber,
              qr_code: qrCodeDataUrl,
              pdf_attachment: pdfDataUri,
            };

            useEmail(templateId, templateParams, setIsLoading);
          }
        }
      } catch (error) {
        toast.error(`Error Adding ${user.name} ${user.surname}`);
        console.error("Error Adding user:", error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedCollection, tableNumber, collections, templateId]
  );

  return (
    <section className="users-list centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      <Container sx={{ position: "absolute", top: "1rem", right: "1rem" }}>
        <h5>Add Users</h5>
      </Container>

      <Container w="100%" m="2rem 0 0 0" center>
        <Select
          onChange={handleCollectionChange}
          value={selectedCollection}
          fontSize="medium"
          options={collections.map((collection) => ({
            value: collection.value,
            label: collection.name,
          }))}
        />
      </Container>

      <CustomTable
        columns={[
          { id: "name", label: "Name" },
          { id: "surname", label: "Surname" },
          { id: "email", label: "Email" },
          { id: "dob", label: "DOB" },
        ]}
        pageName="user"
        rows={users}
        addedUsers={addedUsers}
        addUser={addUser}
        handleReload={handleReload}
        tableNumber={tableNumber}
        setTableNumber={setTableNumber}
        requiresTableNumber={
          collections.find((c) => c.value === selectedCollection)
            ?.requiresTableNumber
        }
      />

      {isLoading && <Loading />}
    </section>
  );
};
