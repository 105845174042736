import React from "react";

const Container = ({
  children,
  w = "fit-content",
  h = "fit-content",
  onClick,
  pd = "0",
  m = "0",
  gap = "0rem",
  center = false,
  end = false,
  column = false,
  rounded = false,
  shadow = false,
  color,
  bg,
  sx,
  className,
  id,
}) => {
  const style = {
    width: w,
    height: h,
    padding: pd,
    margin: m,
    display: "flex",
    justifyContent: center ? "center" : end ? "end" : "start",
    alignItems: center ? "center" : end ? "end" : "start",
    flexDirection: column ? "column" : "row",
    borderRadius: rounded ? "0.5rem" : "0",
    boxShadow: shadow ? "0 0 1.9rem rgba(0, 0, 0, 0.5)" : "none",
    color: color ? color : "white",
    background: bg ? bg : "transparent",
    gap,
    boxSizing: "border-box",
    ...sx,
  };

  return (
    <div className={className} id={id} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

export default Container;
