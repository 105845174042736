import { FaWhatsapp, FaInstagram, FaFacebook, FaTiktok, FaClock, FaPhone, FaEnvelope } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';

export const contactInfo = [
    {
        icon: <FaLocationDot color='rgb(47, 182, 253)' fontSize='1.2rem' />,
        text: 'general.location',
    },
    {
        icon: <FaClock color='rgb(47, 182, 253)' fontSize='1.2rem' />,
        text: 'contacts.time',
    },
    {
        icon: <FaPhone color='rgb(47, 182, 253)' fontSize='1.2rem' />,
        text: 'contacts.phone',
    },
];
  
export const mediaLinks = [
    {
        href: 'https://wa.me/+994107137007',
        icon: <FaWhatsapp fontSize='2rem' />,
    },
    {
        href: 'https://instagram.com/fiesta.az?igshid=NzZhOTFlYzFmZQ==',
        icon: <FaInstagram fontSize='2rem' />,
    },
    {
        href: 'https://www.facebook.com/profile.php?id=61550203935041&mibextid=2JQ9oc',
        icon: <FaFacebook fontSize='2rem' />,
    },
    {
        href: 'https://www.tiktok.com/@fiesta.az?_t=8f8XgdmsXP0&_r=1',
        icon: <FaTiktok fontSize='2rem' />,
    },
    {
        href: 'mailto:fiestagroup@ada.edu.az',
        icon: <FaEnvelope fontSize='2rem' />,
    },
];