import React, { useState, useMemo } from 'react';
import Container from './Container';
import Button from './Button';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Select, MenuItem, FormControl, TextField } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomTable = ({ 
    columns, 
    rows, 
    status, 
    addRow,
    deleteRow,
    handleReload,
    tableNumber, 
    setTableNumber,
    requiresTableNumber 
}) => {
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState('all');
    const [columnFilters, setColumnFilters] = useState({});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
        setPage(0);
    };

    const handleColumnFilterChange = (columnId, value) => {
        setColumnFilters(prevFilters => ({
            ...prevFilters,
            [columnId]: value,
        }));
        setPage(0);
    };

    const filteredRows = useMemo(() => {
        let filtered = rows;

        if (status && statusFilter !== 'all') {
            filtered = filtered.filter(row => {
                const done = row[status];
                return (statusFilter === 'done' && done) || (statusFilter === 'notDone' && !done);
            });
        }

        Object.keys(columnFilters).forEach(columnId => {
            const filterValue = columnFilters[columnId].toLowerCase().trim();
            if (filterValue) {
                filtered = filtered.filter(row => {
                    if (Number.isInteger(Number(row[columnId]))) {
                        return Number(row[columnId]) === Number(filterValue);
                    } else if (typeof row[columnId] === 'string') {
                        return String(row[columnId]).toLowerCase().includes(filterValue);
                    }
                    return false; 
                });
            }
        });

        return filtered;
        
    }, [rows, statusFilter, columnFilters]);

    return (
        <Paper sx={{ width: '90%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 530 }}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {status &&
                                <TableCell align='center'>
                                    <FormControl sx={{ minWidth: 'fit-content', background: 'white', borderRadius: '0.2rem' }}>
                                        <Select
                                            autoWidth
                                            value={statusFilter}
                                            onChange={handleStatusFilterChange}
                                        >
                                            <MenuItem value="all"><p>All</p></MenuItem>
                                            <MenuItem value="done"><CheckBoxIcon color='success' fontSize='medium' /></MenuItem>
                                            <MenuItem value="notDone"><CancelIcon color='error' fontSize='medium' /></MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            }
                            {columns.map((column) => (
                                column.status!='hidden' &&
                                <TableCell
                                    key={column.id}
                                    align={column.align || 'left'}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <Container color='black' column>
                                        <p>{column.label}</p>
                                        <TextField 
                                            type='search'
                                            variant="outlined"
                                            size='small'
                                            onChange={(e) => handleColumnFilterChange(column.id, e.target.value)}
                                            value={columnFilters[column.id] || ''}
                                            sx={{ minWidth: '5rem' }}
                                        />
                                    </Container>
                                </TableCell>
                            ))}
                            {addRow &&
                                <TableCell>
                                    <Container column color='black'> 
                                        {requiresTableNumber && (
                                            <Container w='100%' gap='0.5rem' column color='black'>
                                                <p>Table</p>
                                                <input 
                                                    value={tableNumber} 
                                                    onChange={e => setTableNumber(e.target.value)} 
                                                    type='number' 
                                                    style={{ width: '3rem' }}
                                                />
                                            </Container>
                                        )}
                                    </Container>
                                </TableCell>
                            }
                            {deleteRow &&
                                <TableCell>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            const done = row[status];
                            return (
                            <TableRow 
                                hover 
                                role='checkbox' 
                                tabIndex={-1} 
                                key={row.id}
                            >
                                {status &&
                                    <TableCell align='center'>
                                        {done ? (
                                            <CheckBoxIcon color='success' fontSize='medium' />
                                        ) : (
                                            <CancelIcon color='error' fontSize='medium' />
                                        )}
                                    </TableCell>
                                }
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        column.status!='hidden' &&
                                        <TableCell key={column.id} align={column.align || 'left'}>
                                            {column.id === 'cv'? 
                                                <a href={value} target='_blank'>View CV</a>
                                                : 
                                                column.id === 'receipt'? 
                                                    <a href={value} target='_blank'>View Receipt</a>
                                                    :
                                                    <p>{value}</p>
                                            }
                                        </TableCell>
                                    );
                                })}
                                {addRow &&
                                    <TableCell>
                                        <Container w='100%' gap='1rem' center>
                                            {!done && (
                                                <Button 
                                                    variant='success'
                                                    pd='0.5rem 1rem'
                                                    onClick={() => addRow(row)}
                                                >
                                                    Add
                                                </Button>
                                            )}
                                        </Container>
                                    </TableCell>
                                }
                                {deleteRow &&(
                                    <TableCell>
                                        <Container w='100%' gap='1rem' center>
                                            <Button 
                                                variant='error'
                                                pd='0.5rem 1rem'
                                                onClick={() => deleteRow(row)}
                                            >
                                                Delete
                                            </Button>
                                        </Container>
                                    </TableCell>
                                )}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Container w='100%' center sx={{ justifyContent: handleReload ? 'space-between' : 'flex-end', borderTop: 'solid rgba(222, 222, 222, 0.8) 1px' }}>
                {handleReload &&
                    <Container center>
                        <Button pd='0.5rem' m='0 0.5rem' onClick={handleReload} variant="ordinary" aria-label="reload data">
                            <RefreshIcon fontSize='small' />
                        </Button>
                        {status && (
                            <p style={{color: 'green'}}>
                                Done: {rows.filter(row => row[status]).length}
                            </p>
                        )}
                    </Container>
                }
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component='div'
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>
        </Paper>
    );
};

export default CustomTable;
