import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from '../fb'
import { setDocument } from '../fb/FirebaseFunctions';
import useCurrentUser from '../hooks/useCurrentUser';
import useForm from '../hooks/useForm';
import useEmail from '../hooks/useEmail';
import { Loading } from '../Components/Loading';
import Button from '../Components/Button';
import Container from '../Components/Container';
import Select from '../Components/Select';
import CountryCodeSelector, { countryCodes } from '../Components/CountryCodeSelector';

// Material UI Components and Icons
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

// Material UI Icons
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// IMaskInput
import { IMaskInput } from 'react-imask';

const validateInput = (formData, userData, phoneMask, t) => {
    const { name, surname, dob, phone, email, message } = formData;

    if (!userData) {
        if (/\d/.test(name) || /[^a-zA-Z0-9\s]/.test(name) || name.split(' ').length > 1 || name.length < 2) {
            toast.error(t('toast.invalid-name'));
            return false;
        }
        if (/\d/.test(surname) || /[^a-zA-Z0-9\s]/.test(surname) || surname.split(' ').length > 1 || surname.length < 2) {
            toast.error(t('toast.invalid-surname'));
            return false;
        }
        const dobDay = dob.substring(0,2);
        const dobMonth = dob.substring(3,5);
        const dobYear = dob.substring(6);
        const currentYear = new Date().getFullYear();
        if (dobDay>31 || dobMonth>12 || dobYear <= 1900 || dobYear >= currentYear) {
            toast.error(t('toast.invalid-dob'));
            return false;
        }
    
        const cleanedPhone = phone.replace(/\D/g, '');
        const cleanedMask = phoneMask.replace(/\D/g, '');
        if (cleanedPhone.length !== cleanedMask.length) {
            toast.error(t('toast.invalid-phone'));
            return false;
        }
    
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(email)) {
            toast.error(t('toast.invalid-email'));
            return false;
        }
    }

    if (!message) {
        toast.error(t('toast.invalid-message'));
        return;
    }

    return true;
};

const PhoneMask = React.forwardRef(function PhoneMask(props, ref) {
    const { onChange, mask, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});

const DobMask = React.forwardRef(function DobMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='##/##/####'
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});


export const ContactRequest = () => {
    const { t } = useTranslation('common');
    const [isLoading, setIsLoading] = useState(false);
    const [userData] = useCurrentUser();

    const initialState = {
        name: '',
        surname: '',
        phone: '',
        countryCode: '+994',
        email: '',
        message: '',
    };

    const [formData, handleChange, updateFormData] = useForm(initialState);
    const [phoneMask, setPhoneMask] = useState('00 000 00 00');
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInput(formData, userData, phoneMask, t)) {
            return;
        }

        setIsLoading(false);

        try {
            const today = new Date();
            const currentTime = today.getTime()
            const reversedTimestamp = (9999999999999 - currentTime).toString();
            const dateTime = `${currentTime.toDateString()} ${currentTime.toLocaleTimeString()}`

            const contactRequestData = {
                name: userData? userData.name : formData.name,
                surname: userData? userData.surname : formData.surname,
                phone: userData? userData.phone : `(${formData.countryCode})${formData.phone}`,
                dob: userData? userData.dob : formData.dob,
                email: userData? userData.email : formData.email,
                message: formData.message,
                timestamp: dateTime,
            };

            setDocument('contactRequest', reversedTimestamp, contactRequestData, setIsLoading);

            const templateParams = {
                subject: "New Contact Request",
                user_email: 'fiestagroup@ada.edu.az',
                message: `You have a new contact request from ${formData.name} ${formData.surname}`,
                from_name: "Website",
                from_email: 'fiesta.helpcenter.az@gmail.com',
                reply_to: 'fiesta.helpcenter.az@gmail.com'
            };

            useEmail('template_qs86jrb', templateParams, setIsLoading, false);

            toast.success(t('job-application.success'));
            updateFormData(initialState);
        } catch (error) {
            toast.error(t('job-application.error'));
        }
    };

    const handleCountryCodeChange = (event) => {
        const selectedCode = event.target.value;
        const selectedCountry = countryCodes.find(country => country.code === selectedCode);
        if (selectedCountry) {
            setPhoneMask(selectedCountry.mask);
            updateFormData({
                countryCode: selectedCode,
                phone: ''
            });
        }
    };

    return (
        <Container center w='100%' gap='1rem' m="3rem 0" column>
            <Container 
                center
                column
                shadow
                rounded 
                w='clamp(16.5rem, 30rem, 85%)' 
                pd='2rem 0' 
                gap='1rem'
                bg='white'
            >
                <h3 style={{color: 'black'}}>{t('contactRequest.header')}</h3>

                <form onSubmit={handleSubmit} className='centered w100'>
                    <Container center column w='85%' gap='1rem'>
                        { !userData &&
                            <>
                                <Container end w='100%'>
                                    <TextField name='name' label={t('general.name')} variant='standard' value={formData.name} onChange={handleChange} style={{marginRight: '0.5rem', width: '100%'}}/>
                                    <TextField name='surname' label={t('general.surname')} variant='standard' value={formData.surname} onChange={handleChange} style={{width: '100%'}}/>
                                    <AccountCircle className='icon' />
                                </Container>

                                <Container end w='100%'>
                                    <FormControl variant='standard' style={{width: '100%'}}>
                                        <InputLabel htmlFor='dobMask'>{t('general.dob')}</InputLabel>
                                        <Input
                                            value={formData.dob}
                                            onChange={handleChange}
                                            placeholder='DD/MM/YYYY'
                                            name='dob'
                                            id='dobMask'
                                            inputComponent={DobMask}
                                        />
                                    </FormControl>
                                    <CalendarMonthIcon className='icon' />
                                </Container>

                                <Container end w='100%'>
                                    <CountryCodeSelector
                                        value={formData.countryCode}
                                        onChange={handleCountryCodeChange}
                                        onMaskChange={setPhoneMask}
                                    />
                                    <FormControl variant='standard' style={{width: '100%'}}>
                                        <InputLabel htmlFor='phoneNumber'>{t('general.phone')}</InputLabel>
                                        <Input
                                            value={formData.phone}
                                            onChange={handleChange}
                                            name='phone'
                                            id='phoneNumber'
                                            inputComponent={PhoneMask}
                                            inputProps={{
                                                mask: phoneMask
                                            }}
                                        />
                                    </FormControl>
                                    <PhoneIcon className='icon' />
                                </Container>

                                <Container end w='100%'>
                                    <TextField name='email' label={t('general.email')} variant='standard' value={formData.email} onChange={handleChange} style={{width: '100%'}}/>
                                    <EmailIcon className='icon' />
                                </Container>
                            </>
                        }

                        <Container column w='100%' gap='0.2rem'>
                            <Container color='black' w='100%'>
                                <h5 style={{fontWeight: 600}}>{t('contactRequest.messageHeader')}</h5>
                            </Container>
                            <Container center w='100%'>
                                <textarea
                                    name='message'
                                    placeholder={t('contactRequest.messagePlaceholder')}
                                    value={formData.message}
                                    onChange={handleChange}
                                    style={{ minWidth: '10rem', minHeight: '5rem', maxWidth: '100%', width: '100%' }} 
                                />
                            </Container>
                        </Container>

                        <Button w='100%' type='submit'>
                            <p>{t('contactRequest.submitBtn')}</p>
                        </Button>

                    </Container>
                </form>

                {isLoading && <Loading />}

            </Container>
        </Container>
    );
};