import { useTranslation } from 'react-i18next';
import Container from '../Container';
import Link from '../Link';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { navigationLinks, mediaLinks } from './constants';
import './Footer.css'

export const Footer = () => {
    const {t} = useTranslation('common');
    const navigate = useNavigate();

    return (
        <footer 
            className='centered column' 
            style={{
                width: '100%', 
                background: 'rgb(0, 14, 44)'
            }}
        >

            <Container w='90%' center column  m='1rem 0'>
                <Container w='100%' center m='1rem' gap='1.5rem' className='row-column' sx={{justifyContent: 'space-evenly'}}>

                    <Container w='100%' center column sx={{flex: 1}}>

                        <img style={{width: '6rem'}} src="/images/logo/no-text.png" alt="logo" onClick={() => navigate('/')} />
                        <p><strong>{t('footer.connect')}</strong></p>


                        <Container gap="0.7rem" m='0.5rem 0'>
                            {mediaLinks.map((link, index) => (
                                <Link key={index} href={link.href} children={link.icon} variant="media"/>
                            ))}
                        </Container>

                    </Container>

                    <Container w='90%' gap='2rem'
                        sx={{
                            flex: 4,
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(7rem, 1fr))",
                        }}
                    >

                        {Object.keys(navigationLinks).map((sectionKey) => {
                            const section = navigationLinks[sectionKey];
                            return (
                            <Container key={sectionKey} gap="0.4rem" column>
                                <h5 style={{ marginBottom: "0.3rem" }}><strong>{t(`${section.title}`)}</strong></h5>
                                {section.links.map((link, index) => (
                                    <HashLink key={index} smooth to={link.href} className="footer-hashLink">
                                        {t(`${link.label}`)}
                                    </HashLink>
                                ))}
                            </Container>
                            );
                        })}

                        <Container gap='0.7rem' column>
                            <h5><strong>{t('general.contacts')}</strong></h5>
                            <Container gap='0.4rem' column color='rgb(220, 220, 220)'>
                                <p>fiestagroup@ada.edu.az</p>
                                <p>{t('contacts.phone')}</p>
                                <p>{t('general.location')}
                                </p>
                            </Container>

                        </Container>

                    </Container>


                </Container>
                <div className='line'></div>
                <p style={{color: 'rgba(255, 255, 255, 0.7'}}>{t('footer.copyright')}</p>
            </Container>
 
        </footer>
    );
}