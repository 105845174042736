import QRCode from 'qrcode';
import { jsPDF } from 'jspdf';

export const generateQRCode = async (text, encode = null) => {
    try {
        const encodedText = encode? encode(text) : text;
        return await QRCode.toDataURL(encodedText, { width: 200, margin: 1 });
    } catch (error) {
        console.error('Error generating QR code:', error);
        return null;
    }
};

const generatePDFWithQRCodes = async (collection) => {
    const doc = new jsPDF();
    const qrCodeSize = 40;
    const margin = 10;
    const rows = 5;
    const columns = 4;

    for (let i = 0; i < collection.length; i++) {
        const qrCodeDataUrl = await generateQRCode(collection[i].id);
        if (qrCodeDataUrl) {
            const x = margin + (i % columns) * (qrCodeSize + margin);
            const y = margin + Math.floor(i / columns) % rows * (qrCodeSize + margin);
            doc.addImage(qrCodeDataUrl, 'PNG', x, y, qrCodeSize, qrCodeSize);
            
            if ((i + 1) % (rows * columns) === 0 && i + 1 !== collection.length) doc.addPage();
        }
    }

    return doc.output('datauristring');
};

const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

export const printPDFWithQRCodes = async (collection) => {
    const pdfDataUri = await generatePDFWithQRCodes(collection);
    const pdfBlob = dataURItoBlob(pdfDataUri);
    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl);
};