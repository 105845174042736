import { FaWhatsapp, FaInstagram, FaFacebook, FaTiktok, FaEnvelope } from 'react-icons/fa';

export const navigationLinks = {
    home: {
      title: "navbar.home",
      links: [
        { label: "home.stats.title", href: "/home/#stats" },
        { label: "home.services.header", href: "/home/#services" },
        { label: "home.clients.header", href: "/home/#clients" },
      ],
    },
    about: {
      title: "navbar.aboutUs",
      links: [
        { label: "aboutUs.valuesTitle", href: "/about/#values" },
      ],
    },
    portfolio: {
      title: "navbar.portfolio",
      links: [
        { label: "portfolio.2023", href: "/portfolio/#2023" },
        { label: "portfolio.2022", href: "/portfolio/#2022" },
      ],
    },
  };

export const mediaLinks = [
    {
        href: 'https://wa.me/+994107137007',
        icon: <FaWhatsapp fontSize='1.5rem' />,
    },
    {
        href: 'https://instagram.com/fiesta.az?igshid=NzZhOTFlYzFmZQ==',
        icon: <FaInstagram fontSize='1.5rem' />,
    },
    {
        href: 'https://www.facebook.com/profile.php?id=61550203935041&mibextid=2JQ9oc',
        icon: <FaFacebook fontSize='1.5rem' />,
    },
    {
        href: 'https://www.tiktok.com/@fiesta.az?_t=8f8XgdmsXP0&_r=1',
        icon: <FaTiktok fontSize='1.5rem' />,
    },
    {
        href: 'mailto:fiestagroup@ada.edu.az',
        icon: <FaEnvelope fontSize='1.5rem' />,
    },
];