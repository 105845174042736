import './Welcome.css';
import { useTranslation } from "react-i18next";

export const Welcome = () => {

    const {t} = useTranslation("common");
    return (
        <section className='welcome-info'>
            <div className='welcome-video-container centered'>

                <div aria-hidden='true' data-background--hide='false' data-component='background' data-type='video' data-data-loaded='true'>
                    <div className='welcome-video'>
                        <iframe
                            title='FIESTA.AZ'
                            width='100%'
                            height='100%'
                            loading='lazy'
                            src='https://www.youtube.com/embed/U8vp-unobgw?vq=hd1080p&autoplay=1&loop=1&mute=1&controls=0&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=0&enablejsapi=1&playlist=U8vp-unobgw' 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        />
                    </div>
                </div>


                <div className='welcome-video-overlay'></div>

                <div className='welcome-video-text'>
                    <h1><span style={{color: 'rgb(47, 182, 253)'}}>{t('home.welcome.welcome')}</span><br/>{t('home.welcome.fiesta')}</h1>
                </div>
                
            </div>
        </section>
    );
}