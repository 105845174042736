import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setDocument } from "../../fb/FirebaseFunctions";
import { toast } from "react-toastify";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Loading } from "../../Components/Loading";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import useForm from "../../hooks/useForm";
import "./Feedback.css";

export const Feedback = () => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);

  const initialState = {
    selectedEvent: "",
    rating: null,
    message: "",
  }
  const [formData, handleChange, updateFormData] = useForm(initialState);

  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserEmail(user ? user.email : null);
    });
    return unsubscribe;
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { selectedEvent, rating, message } = formData;

    if (!selectedEvent || !message || !rating) {
      toast.error(t('error.allFieldsRequired'));
      return;
    }

    setIsLoading(true);
    try {
        const currentTime = new Date();
        const reversedTimestamp = (9999999999999 - currentTime.getTime()).toString();
        const dateTime = `${currentTime.toDateString()} ${currentTime.toLocaleTimeString()}`;
        
        const feedbackData = {
            event: selectedEvent,
            rating: rating,
            dateTime: dateTime,
            sender: userEmail || "Anonymous",
            feedback: message,
        };

        setDocument("feedbacks", reversedTimestamp, feedbackData, setIsLoading);
        
        toast.success(t('feedback.success'));
        updateFormData(initialState);
    } catch (error) {
        toast.error(t('feedback.error'));
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <section className="feedback-form centered column">
      <Container center column w="100%" gap="1rem" pd="3rem 0">
        <h2>{t("navbar.feedback")}</h2>

        <form onSubmit={handleSubmit} className="centered">
          <Container center column w="90%" gap="1rem">
            <select
              name="selectedEvent"
              value={formData.selectedEvent}
              onChange={handleChange}
              required
              style={{ width: "100%" }}
            >
              <option value="">{t("feedback.events")}</option>
              <option value="Bloody Jack Halloween">Bloody Jack Halloween</option>
            </select>

            <Rating
              name="rating"
              style={{ fontSize: "2.5rem" }}
              value={formData.rating}
              onChange={(e, newRating) => {
                updateFormData({ rating: newRating });
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.75 }} fontSize="inherit" />
              }
            />

            <textarea
              name="message"
              placeholder="Your feedback..."
              value={formData.message}
              onChange={handleChange}
              required
              style={{ width: "100%" }}
            />

            <Button type="submit" w="100%">
              {t("feedback.submitBtn")}
            </Button>
          </Container>
        </form>
      </Container>

      {isLoading && <Loading />}
    </section>
  );
};
