import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18n from "i18next";

import common_az from "./translations/az/common.json";
import common_en from "./translations/en/common.json";
import common_ru from "./translations/ru/common.json";

i18n.init({
    interpolation: { escapeValue: false },
    lng: 'en',                              
    resources: {
        en: {
            common: common_en             
        },
        az: {
            common: common_az
        },
        ru: {
            common: common_ru
      },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
