import './Clients.css';
import Marquee from "react-fast-marquee";
import { useTranslation } from 'react-i18next';

export const Clients = () =>{
    const {t} = useTranslation('common');

    return(
        <section className='our-clients centered column' id='clients'>
          <h1>{t('home.clients.header')}</h1>
          <Marquee pauseOnHover>
            {cards.map((card, index) => {
              const imgUrl = `/images/our-clients/${card.imgUrl}`
              return (
                <div key={index} className='client'>
                  <img src={imgUrl} alt={card.imgAlt}></img>
                </div>
              );
            })}
          </Marquee>
        </section>
    );
}

const cards = [
  {
    imgUrl: 'ada-uni.png',
    imgAlt: 'ADA University'
  },

  {
    imgUrl: 'celt.png',
    imgAlt: 'CELT Colleges'
  },

  {
    imgUrl: 'goal-plus.png',
    imgAlt: 'Goal Plus'
  },

  {
    imgUrl: '189-school.png',
    imgAlt: '189-190 School'
  },

  {
    imgUrl: 'ada-school.png',
    imgAlt: 'ADA School'
  },

  {
    imgUrl: 'landau.png',
    imgAlt: 'Landau School'
  },

  {
    imgUrl: 'ilham-osman.png',
    imgAlt: 'Ilham Osmanov'
  },

  {
    imgUrl: '49-school.png',
    imgAlt: '49 school'
  }
]