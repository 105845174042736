import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Modal from "@mui/material/Modal";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "@mui/icons-material/Close";
import { EventPayment } from "../../forms/EventPayment";
import { TableReservation } from "../../forms/TableReservation";
import Button from "../../Components/Button";
import Container from "../../Components/Container";
import "./Events.css";

// Constants for event data
const EVENTS_DATA = [
  {
    eventName: <span>ADA School</span>,
    eventType: "New Year Party",
    imgSrc: "/images/events/adaSchool.jpeg",
    collection: "ADA_School_NY25",
    payment: true,
    card: "5243 7544 4744 9677",
    bank: "UniBank",
    expirationDate: "09/27",
    reservation: false,
    info: {
      en: (
        <p>
          <strong>ATTENTION: ADA SCHOOL STUDENTS ONLY</strong>
          <br />
          <br />
          -- Payments submitted by non-ADA School students will not be accepted
          and will be canceled --
          <br />
          <br />
          <strong>ADA'25 New Year's Gala</strong>
          <br />
          ✨ Step into a night of elegance, joy, and celebration! ✨
          <br />
          <br />
          Join us for an unforgettable evening filled with special memories,
          lively music, and enchanting performances. Celebrate the season with
          your friends and classmates as we bid farewell to the year and welcome
          2025 in style!
          <br />
          <br />
          <h4>Event Highlights</h4>
          <br />
          <strong>Live Music & Entertainment:</strong>
          <br />
          <br />
          🎶 DJ AX spinning the beats to keep you dancing all night long.
          <br />
          🎤 AgaNadirov, renowned host of the Maska Show, will guide us through
          the evening.
          <br />
          🎸 Special performances by Lazer Team and Gaga Band, bringing a unique
          musical experience.
          <br />
          <br />
          <strong>Capture the Magic:</strong>
          <br />
          <br />
          📸 A photo booth with a professional media team to ensure every moment
          and emotion is preserved.
          <br />
          <br />
          <strong>Games & Prizes:</strong>
          <br />
          <br />
          🎯 Engage in fun activities during the day with exciting prizes to
          win!
          <br />
          <br />
          <strong>Grand Finale:</strong>
          <br />
          <br />
          🎂 A special cake-cutting ceremony to wrap up the night with a sweet
          touch.
          <br />
          <br />
          <strong>EVENT DETAILS</strong>
          <br />
          🗓️ <strong>Date & Time:</strong> 29 December, 18:00
          <br />
          📍 <strong>Venue:</strong> Ritz Carlton, Shusha Ballroom
          <br />
          <br />
          <strong>Ticket Purchase Rules</strong>
          <br />
          <br />
          <strong>Price:</strong> 180 AZN
          <br />
          <strong>Deadline:</strong> 10 December
          <br />
          <br />
          Eligibility:
          <br />
          <br />
          - Tickets are exclusively for ADA School students.
          <br />
          - Payments from non-ADA School students will not be processed and will
          be canceled.
          <br />
          <br />
          <strong>Payment Guidelines:</strong>
          <br />
          <br />
          Follow the specified payment instructions carefully.
          <br />
          <br />
          Ensure that all details are accurate to avoid delays or cancellations.
          <br />
          <br />
          🎟️ Reserve your spot now and make this New Year's celebration truly
          unforgettable!
        </p>
      ),
    },
  },
  {
    eventName: <span>ADA University</span>,
    eventType: "New Year Party",
    imgSrc: "/images/events/adaUni.jpg",
    collection: "ADA_University_NY25",
    payment: true,
    card: "5271 6147 2698 0740",
    bank: "Pasha Bank",
    expirationDate: "02/27",
    reservation: true,
    info: {
      en: (
        <p>
          <h4 style={{ textAlign: "center" }}>
            <strong>Masquerade Ball: Royal Venice</strong>
          </h4>
          <br />
          <strong>EVENT DETAILS</strong>
          <br />
          🗓️ <strong>Date & Time:</strong> 22 December, 18:00
          <br />
          📍 <strong>Venue:</strong> Grand Palace (Böyük Saray)
          <br />
          <br />
          <strong>Price:</strong> 185 AZN
          <br />
          <br />
          <strong>🎶 Entertainment Highlights:</strong>
          <br />
          <br />- Electrifying beats by <strong>DJ Kanan Drums</strong>
          <br />- Captivating rhythms and special shows by{" "}
          <strong>DJ AX</strong>
          <br />- <strong>Surprise Performances & Special Star Guest</strong>
          <br />
          <br />
          <strong>🎭 Dress Code:</strong>
          <br />
          Guests are encouraged to wear a mask—bring your unique touch of
          intrigue and style!
          <br />
          <br />
          <strong>✨ Exclusive Features:</strong>
          <br />
          - Enjoy a special *gala menu* featuring a variety of food & beverages
          <br />- Capture memories in our <strong>Selfie Rooms</strong> :
          Photobooth & LED Room
          <br />- Be mesmerized by a Spectacular <strong>Fireworks</strong> Show
          🎆
          <br />- Let our <strong>Media Team</strong> turn your moments into
          cherished memories
          <br />- Explore our first-ever Special <strong>
            LED Photo Zone
          </strong>{" "}
          with diverse backgrounds
          <br />- Participate in exciting <strong>competitions</strong> and win
          fabulous prizes
          <br />
          <br />
          Seats are limited, and the deadline to secure your spot is{" "}
          <strong>December 15th</strong> — don’t miss out on this magical
          evening of Venetian elegance, music, and unforgettable moments.
          <br />
          <br />
          <strong>For Info & Tickets:</strong>
          <br />
          <br />
          📞 +994107137007
          <br />
          📨 Email: fiestagroup@ada.edu.az
          <br />
          <br />
          We look forward to sharing this extraordinary celebration with you!
        </p>
      ),
    },
  },
];

const EventCard = ({ event, onEventClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="event centered">
      <Button pd="0" onClick={() => onEventClick(event)}>
        {!imageLoaded && (
          <Skeleton
            variant="rounded"
            width={310}
            height={180}
            sx={{ background: "rgb(120, 120, 120)" }}
            animation="wave"
          />
        )}
        <img
          src={event.imgSrc}
          style={{ display: imageLoaded ? "block" : "none" }}
          onLoad={() => setImageLoaded(true)}
          alt={event.eventName}
        />
      </Button>

      <div className="event-info">
        {!imageLoaded ? (
          <Container column gap="0.5rem">
            <Skeleton
              variant="rounded"
              width={100}
              height={20}
              sx={{ background: "rgb(120, 120, 120)" }}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={80}
              height={20}
              sx={{ background: "rgb(120, 120, 120)" }}
              animation="wave"
            />
          </Container>
        ) : (
          <>
            <h5>{event.eventName}</h5>
            <p>{event.eventType}</p>
          </>
        )}
      </div>
    </div>
  );
};

// Main Events Component
export const Events = () => {
  const { t, i18n } = useTranslation(["common"]);

  const [paneVideo, setPaneVideo] = useState({
    visible: false,
    selectedEvent: null,
  });

  const [modalState, setModalState] = useState({
    open: false,
    formType: "payment",
  });

  const handleEventClick = (event) => {
    setPaneVideo({
      visible: true,
      selectedEvent: event,
    });
  };

  const handleModalOpen = (formType) => {
    setModalState({
      open: true,
      formType,
    });
  };

  const handleModalClose = () => {
    setModalState({ open: false, formType: "payment" });
  };

  const renderEventInfo = (event) => {
    // Select language-specific info or fallback to English
    const languageMap = {
      en: event.info.en,
      ru: event.info.ru || event.info.en,
      az: event.info.az || event.info.en,
    };
    return languageMap[i18n.language] || event.info.en;
  };

  return (
    <section className="events centered">
      {/* Sliding Pane for Event Details */}
      <SlidingPane
        overlayClassName="loading-overlay"
        isOpen={paneVideo.visible}
        onRequestClose={() =>
          setPaneVideo({ visible: false, selectedEvent: null })
        }
      >
        {paneVideo.selectedEvent?.imgSrc && (
          <img
            src={paneVideo.selectedEvent.imgSrc}
            className="pane-img"
            alt={paneVideo.selectedEvent.eventName}
          />
        )}

        <Container w="90%" m="1rem 0 2rem 0" center column gap="1rem">
          <h3>{paneVideo.selectedEvent?.eventName}</h3>
          {paneVideo.selectedEvent && renderEventInfo(paneVideo.selectedEvent)}
        </Container>

        {/* Reservation and Payment Buttons */}
        {(paneVideo.selectedEvent?.reservation ||
          paneVideo.selectedEvent?.payment) && (
          <Container
            center
            w="80%"
            gap="1rem"
            m="0 0 2rem 0"
            className="row-column"
            sx={{ justifyContent: "space-evenly" }}
          >
            {paneVideo.selectedEvent?.reservation && (
              <Button
                variant="modern"
                shadow
                pd="2rem 0"
                w="100%"
                onClick={() => handleModalOpen("reservation")}
              >
                <Container center w="100%" column color="rgb(0, 14, 44)">
                  <h4>
                    <strong>Reserve Table</strong>
                  </h4>
                </Container>
              </Button>
            )}
            {paneVideo.selectedEvent?.payment && (
              <Button
                variant="modern"
                shadow
                pd="2rem 0"
                w="100%"
                onClick={() => handleModalOpen("payment")}
              >
                <Container center w="100%" column color="rgb(0, 14, 44)">
                  <h4>
                    <strong>Upload Payment</strong>
                  </h4>
                </Container>
              </Button>
            )}
          </Container>
        )}

        {/* Modal for Payment and Reservation */}
        <Modal
          open={modalState.open}
          onClose={handleModalClose}
          sx={{ overflow: "scroll" }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              minHeight: "100%",
              overflow: "scroll",
              position: "relative",
            }}
            className="centered"
          >
            <Button
              variant="error"
              pd="0.2rem"
              onClick={handleModalClose}
              sx={{
                position: "fixed",
                top: "10px",
                right: "10px",
              }}
            >
              <CloseIcon />
            </Button>

            {modalState.formType === "payment" && paneVideo.selectedEvent && (
              <EventPayment
                collection={paneVideo.selectedEvent.collection || ""}
                ticketOptions={paneVideo.selectedEvent.paymentTicketOptions}
                card={paneVideo.selectedEvent.card}
                bank={paneVideo.selectedEvent.bank}
                expirationDate={paneVideo.selectedEvent.expirationDate}
              />
            )}

            {modalState.formType === "reservation" &&
              paneVideo.selectedEvent && (
                <TableReservation
                  ticketOptions={
                    paneVideo.selectedEvent.reservationTicketOptions
                  }
                />
              )}
          </div>
        </Modal>
      </SlidingPane>

      {/* Events Container */}
      <Container w="90%" column gap="1rem" m="2rem 0 0 0">
        <div className="event-container">
          {EVENTS_DATA.map((event, index) => (
            <EventCard
              key={index}
              event={event}
              onEventClick={handleEventClick}
            />
          ))}
        </div>
      </Container>

      {/* More Events Section */}
      <Container w="100%" center m="3rem 0">
        <h2 style={{ textAlign: "center" }}>
          {t("events.more")}
          <br />
          {t("events.dont-miss")}
        </h2>
      </Container>
    </section>
  );
};
