import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { useState } from "react";
import { Loading } from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import CustomTable from "../../Components/CustomTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const JobApplications = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const jobApplications = useFirestoreCollection("jobApplications", setIsLoading);

  return (
    <section className="users-list centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      <Container w="100%" m="2rem 0 0 0" center>
        <h3>Job Applications</h3>
      </Container>

      <CustomTable
        columns={[
          { id: "timestamp", label: "Date" },
          { id: "name", label: "Name" },
          { id: "surname", label: "Surname" },
          { id: "email", label: "Email" },
          { id: "position", label: "Position" },
          { id: "message", label: "Message" },
          { id: "phone", label: "Phone" },
          { id: "cv", label: "CV" },
        ]}
        rows={jobApplications}
      />

      {isLoading && <Loading />}
    </section>
  );
};
