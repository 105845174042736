import { Welcome } from "./Components/Welcome";
import { Stats } from "./Components/Stats";
import { Remember } from "./Components/Remember";
import { Services } from "./Components/Services";
import { Clients } from "./Components/Clients";
import { StudyEvent } from "./Components/StudyEvent";
import { CreateEvent } from "./Components/CreateEvent";
import { useState } from "react";
import { Loading } from "../../Components/Loading";
import Container from "../../Components/Container";

export const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return (
    <Container w="100%" column>
      <Welcome />

      <Stats />

      <Remember />

      <Services />

      <Clients />

      <StudyEvent />

      <CreateEvent />

      {isLoading && <Loading />}
    </Container>
  );
};
