import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../fb';
import useFirestoreCollection from '../../hooks/useFirestoreCollection';
import useCurrentUser from '../../hooks/useCurrentUser';
import { toast } from 'react-toastify';
import { NavLinks } from './Components/NavLinks';
import { NavEnd } from './Components/NavEnd';
import './Navbar.css';

import MenuIcon from '@mui/icons-material/Menu';
import Button from '../Button';

import Drawer from '@mui/material/Drawer';
import Container from '../Container';

export const Navbar = () => {
  const navigate = useNavigate();
  const [userData] = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  const [signedIn, setSignedIn] = useState(userData !== null);

  const defaultRoles = {
    isAdmin: false,
    isTeam: false,
    isReception: false,
    isVolunteer: false,
    volunteerGroup: '',
  };
  const [userRoles, setUserRoles] = useState(defaultRoles);

  const team = useFirestoreCollection('team');
  const volunteers = useFirestoreCollection('volunteers');

  useEffect(() => {
    if (userData){
      const currentUserEmail = userData.email;
      const currentUserTeam = team.find((member) => member.email === currentUserEmail);
      const currentUserVolunteer = volunteers.find((volunteer) => volunteer.id === currentUserEmail);

      setUserRoles({
        isAdmin: currentUserTeam?.role === 'admin' || false,
        isTeam: Boolean(currentUserTeam),
        isReception: currentUserTeam?.role === 'reception' || false,
        isVolunteer: Boolean(currentUserVolunteer),
        volunteerGroup: currentUserVolunteer?.group || '',
      });

      setSignedIn(true);
    } else {
      setSignedIn(false);
      setUserRoles(defaultRoles);
    }
  }, [userData]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        toast.success('Signed Out.');
        navigate('/');
        window.location.reload();
        setSignedIn(false);
        setUserRoles(defaultRoles);
      })
      .catch(() => {
        toast.error('Failed to Sign Out.');
      });
    toggleMenu();
  };

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <nav className="centered">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src="/images/logo/no-text.png" alt="logo" onClick={() => navigate('/')} />
        </div>

        {/* Desktop Menu */}
        <NavLinks className="navbar-menu desktop" userRoles={userRoles} />
        <NavEnd className="desktop-menu-footer" handleSignOut={handleSignOut} />

        {/* Mobile Menu Button */}
        <Container className="mobile-menu-button">
          <Button variant='blue' onClick={toggleMenu} className="menu-toggle">
            <MenuIcon fontSize='large' />
          </Button>
        </Container>
      </div>

      {/* Mobile Menu */}
      <Drawer open={isOpen} anchor="right" onClose={toggleMenu} sx={{ display: { md: 'none' } }}>
        <div className="mobile-menu">
          <NavLinks className="mobile-menu-items centered column" toggleMenu={toggleMenu} userRoles={userRoles} />
          <NavEnd className="mobile-menu-footer" toggleMenu={toggleMenu} handleSignOut={handleSignOut} />
        </div>
      </Drawer>
    </nav>
  );
};
